'use client'

import {Container} from 'layout/Container'
import {NewsCardType} from 'components/cards/NewsCard'
import {NewsOverview} from './NewsOverview'
import css from './NewsRelated.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const NewsRelated: React.FC<{
	news: NewsCardType[] | null
	className?: string
}> = ({news, className}) => {
	if (!news || news.length === 0) return null

	return (
		<div className={styles.newsrelated.with(className)()}>
			<Container>
				<NewsOverview news={news} />
			</Container>
		</div>
	)
}
