'use client'

import {
	QueryClient,
	QueryClientProvider as ReactQueryClientProvider
} from '@tanstack/react-query'

import {useState} from 'react'

export default function QueryClientProvider({children}) {
	const [queryClient] = useState(() => new QueryClient())

	return (
		<ReactQueryClientProvider client={queryClient}>
			{children}
		</ReactQueryClientProvider>
	)
}
