import {createContext, useContext} from 'react'

export const ParamsContext = createContext<{
	setParams: (queryParams: URLSearchParams) => void
	params: URLSearchParams
} | null>(null)

export const useParams = () => {
	const context = useContext(ParamsContext)
	if (!context) {
		throw new Error(
			`useParams can only be used inside a Fetcher or PaginatedFetcher component`
		)
	}
	return context
}
