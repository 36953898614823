'use client'

import {Pagination} from 'layout/pagination/Pagination'
import {usePaginatedFetcher} from 'layout/fetcher/PaginatedFetcher'
import {useParams} from 'layout/fetcher/params'

export const ResultPagination: React.FC = () => {
	const {data, isLoading, ...pagination} = usePaginatedFetcher<{}>()
	const {params, setParams} = useParams()

	const perPage = 5
	const pageGroups: number[][] = []
	for (let i = 0; i < Math.ceil(pagination.last_page / perPage); i++) {
		const pageGroup: number[] = []
		for (let j = 1; j <= perPage; j++) {
			if (j + i * perPage > pagination.last_page) continue
			pageGroup.push(j + i * perPage)
		}
		pageGroups.push(pageGroup)
	}
	const currentPageGroupId = Math.ceil(pagination.current_page / perPage) - 1

	return (
		<Pagination
			currentPage={pagination.current_page}
			next={currentPageGroupId + 1 < pageGroups.length}
			prev={currentPageGroupId > 0}
			visiblePages={
				pageGroups.length && pageGroups[currentPageGroupId]
					? pageGroups[currentPageGroupId]
					: []
			}
			params={params}
			onClickHandler={(p) => {
				pagination.setPage(p)
			}}
		/>
	)
}
