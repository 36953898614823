'use client'

import React, {useState} from 'react'

import {CookiesMissing} from './cookies/CookiesMissing'
import css from './Video.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {useCookiebot} from 'util/cookiebot'

const styles = fromModule(css)
const DynamicReactPlayer = dynamic(() => import('react-player/lazy'), {
	ssr: false
})

export const Video: React.FC<{url: string}> = ({url}) => {
	const cookiebot = useCookiebot()
	const [isPlaying, setPlaying] = useState<boolean>(false)

	if (!url) return null

	return (
		<div className={styles.video()}>
			<div
				onClick={() =>
					cookiebot.hasConsented('marketing') && setPlaying(!isPlaying)
				}
				className={styles.video.wrapper()}
			>
				<CookiesMissing category="marketing">
					<DynamicReactPlayer
						url={url}
						playing={isPlaying}
						height="100%"
						width="100%"
						controls
						light
					/>
				</CookiesMissing>
			</div>
		</div>
	)
}
