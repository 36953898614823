import {CalendarItem, getTheme} from './CalendarItem'

import {CalendarMonth} from './CalendarMonth'
import {PersonFunction} from 'server/personfunction'
import css from './CalendarGroup.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

type Item = CalendarItem & {
	moreInfo: PersonFunction | null
}

export type CalendarGroup = {
	month: number
	items: Item[]
}

export const CalendarGroup: React.FC<CalendarGroup & {isPast: boolean}> = ({
	month,
	items,
	isPast
}) => {
	if (!items || items.length === 0) return null

	items.sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))

	const firstItem = items[0]
	const firstItemTheme =
		firstItem.channel !== 'calendar_item'
			? getTheme(
					firstItem.channel === 'education_detail' ||
						firstItem.channel === 'refresher_course_detail'
						? firstItem.target_audience[0] || ''
						: ''
			  )
			: null

	return (
		<div className={styles.calendargroup()}>
			<CalendarMonth month={month} isPast={isPast} theme={firstItemTheme} />
			<div className={styles.calendargroup.items()}>
				{items.map((item, index) => (
					<CalendarItem {...item} key={index} />
				))}
			</div>
		</div>
	)
}
