import {Variant} from 'util/styler'
import {fromModule} from 'util/styler/Styler'
import css from './LabelValue.module.scss'

const styles = fromModule(css)

export const LabelValue: React.FC<{
	label?: string
	value: string | JSX.Element
	width?: number
	mod?: Variant<'lineclamp'>
}> = ({label, value, width, mod}) => {
	if (!label && !value) return null
	const flexBasis = width ? `${width}px` : 'auto'

	return (
		<div className={styles.labelvalue()}>
			{label && (
				<b
					className={styles.labelvalue.label()}
					style={{flex: `0 0 ${flexBasis}`}}
				>
					{label}
				</b>
			)}
			{value && (
				<div className={styles.labelvalue.value.mod(mod)()}>{value}</div>
			)}
		</div>
	)
}
