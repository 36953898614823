import {ChangeEventHandler} from 'react'
import css from './Checkbox.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Checkbox: React.FC<{
	name: string
	value: string
	label?: string
	checked?: boolean
	onChange: ChangeEventHandler<HTMLInputElement> | undefined
}> = ({name, value, label, onChange, checked}) => {
	if (!name && !value) return null

	return (
		<label className={styles.checkbox()}>
			<input
				type="checkbox"
				name={name}
				value={value}
				className={styles.checkbox.input()}
				onChange={onChange}
				checked={checked}
			/>
			<span className={styles.checkbox.checkbox()} />
			<span className={styles.checkbox.label()}>{label || value}</span>
		</label>
	)
}
