'use client'

import {endOfDay, isFuture, isThisMonth} from 'date-fns'
import {useCallback, useEffect, useState} from 'react'

import {CalendarGroup} from './CalendarGroup'
import css from './CalendarGroups.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'

const styles = fromModule(css)

export type CalendarGroups = CalendarGroup[]

const getUpcomingGroups = (groups: CalendarGroups) => {
	const upcomingGroups = groups
		.filter(
			({month}) =>
				month && (isFuture(new Date(month)) || isThisMonth(new Date(month)))
		)
		.map((group) => {
			if (!isThisMonth(new Date(group.month))) return group
			return {
				...group,
				items: group.items.filter((item) => {
					const endDate = item.end_date ? item.end_date : item.date
					return isFuture(endOfDay(new Date(endDate)))
				})
			}
		})
		.filter(({items}) => items.length > 0)
	return upcomingGroups.sort(sortGroupsDesc)
}

const getPastGroups = (groups: CalendarGroups) => {
	const pastGroups = groups
		.filter(
			({month}) =>
				(month && !isFuture(new Date(month))) || isThisMonth(new Date(month))
		)
		.map((group) => {
			if (!isThisMonth(new Date(group.month))) return group
			return {
				...group,
				items: group.items.filter((item) => {
					const endDate = item.end_date ? item.end_date : item.date
					return !isFuture(endOfDay(new Date(endDate)))
				})
			}
		})
		.filter(({items}) => items.length > 0)
	return pastGroups.sort(sortGroupsDesc)
}

const sortGroupsDesc = (a: CalendarGroup, b: CalendarGroup) =>
	a.month < b.month ? -1 : a.month > b.month ? 1 : 0

const sortGroupsAsc = (a: CalendarGroup, b: CalendarGroup) =>
	a.month < b.month ? 1 : a.month > b.month ? -1 : 0

export const CalendarGroups: React.FC<{
	groups: CalendarGroups
	className?: string
	notFound: string
}> = ({groups, className, notFound}) => {
	const addItemsPer: number = 2
	const [totalItems, setTotalItems] = useState<number>(
		(groups && groups.length) || 0
	)
	const [upcomingGroups, setUpcomingGroups] = useState<CalendarGroups>(
		getUpcomingGroups(groups)
	)
	const [pastGroups, setPastGroups] = useState<CalendarGroups>(
		getPastGroups(groups)
	)
	const [visibleItems, setVisibleItems] = useState<number>(
		!upcomingGroups.length ? 2 : 0
	)

	const [showLoadPastButton, setShowLoadPastButton] = useState<boolean>(
		visibleItems < pastGroups.length
	)

	const loadPast = useCallback(() => {
		let newValue = visibleItems + addItemsPer
		if (newValue > pastGroups.length) newValue = pastGroups.length
		setVisibleItems(newValue)
	}, [pastGroups.length, visibleItems])

	useEffect(() => {
		if (upcomingGroups.length === 0) loadPast()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const upcomingGroups = getUpcomingGroups(groups)
		const pastGroups = getPastGroups(groups)
		const visibleItems = !upcomingGroups.length ? 2 : 0
		setVisibleItems(visibleItems)
		setUpcomingGroups(upcomingGroups)
		setPastGroups(pastGroups)
		setShowLoadPastButton(visibleItems < pastGroups.length)
		setTotalItems((groups && groups.length) || 0)
	}, [groups])

	useEffect(() => {
		setShowLoadPastButton(visibleItems < pastGroups.length)
	}, [visibleItems, pastGroups.length])

	if (totalItems === 0)
		return (
			<div className={styles.calendargroups.with(className)()}>{notFound}</div>
		)

	return (
		<div className={styles.calendargroups.with(className)()}>
			{showLoadPastButton && (
				<button onClick={loadPast} className={styles.calendargroups.loadpast()}>
					{nl.calendar.loadpast}
				</button>
			)}
			{pastGroups
				.slice(pastGroups.length - visibleItems, pastGroups.length)
				.map((group, index) => (
					<CalendarGroup
						{...group}
						isPast={true}
						key={`${group.month}_${index}`}
					/>
				))}
			{upcomingGroups.map((group, index) => (
				<CalendarGroup
					{...group}
					isPast={false}
					key={`${group.month}_${index}`}
				/>
			))}
		</div>
	)
}
