'use client'

import {createContext, useContext, useEffect, useState} from 'react'
import {usePathname, useRouter, useSearchParams} from 'next/navigation'

import {Paginated} from 'pages/api/fetch'
import {ParamsContext} from 'layout/fetcher/params'
import axios from 'axios'

interface Props<T> {
	initial: Paginated<T>
	/* A function which returns an url to fetch data or null if the default data can be used */
	calculateUrl: (queryParams: URLSearchParams) => string | null
	children: React.ReactNode
}

const FetcherContext = createContext<
	| ({
			setPage: (page: number) => void
			isLoading: boolean
	  } & Paginated<any>)
	| null
>(null)

export const usePaginatedFetcher = <T extends unknown>() => {
	const context = useContext(FetcherContext)
	if (!context) {
		throw new Error(`useFetch can only be used inside a Fetcher component`)
	}
	return context as Omit<typeof context, 'data'> & {data: T[]}
}

export const PaginatedFetcher = <T extends unknown>(props: Props<T>) => {
	const {initial, children} = props
	const [firstRender, setFirstRender] = useState(true)

	useEffect(() => {
		setFirstRender(false)
	}, [])

	if (firstRender) {
		const params = new URLSearchParams() as any
		const setPage = () => {}
		const setParams = () => {}
		return (
			<ParamsContext.Provider value={{params, setParams}}>
				<FetcherContext.Provider
					value={{...initial, setPage, isLoading: false}}
				>
					{children}
				</FetcherContext.Provider>
			</ParamsContext.Provider>
		)
	}

	return <DynamicData {...props} />
}

const DynamicData = <T extends unknown>(props: Props<T>) => {
	const path = usePathname()
	const router = useRouter()
	const {calculateUrl, initial, children} = props
	const params: URLSearchParams =
		(useSearchParams() as any) || new URLSearchParams()
	const [isLoading, setIsLoading] = useState(true)
	const url = calculateUrl(params)
	const [data, setData] = useState<Paginated<T> | null>(!url ? initial : null)

	const setParams = (params: URLSearchParams) => {
		router.push(`${path}?${params.toString()}`)
	}

	const setPage = (page: number) => {
		const newParams = new URLSearchParams(params.toString())
		newParams.delete('page')
		newParams.append('page', '' + page)
		router.push(`${path}?${newParams.toString()}`)
	}

	useEffect(() => {
		if (!url) {
			setData(initial)
			setIsLoading(false)
			return
		}

		setIsLoading(true)
		const controller = new AbortController()
		axios
			.get(url, {signal: controller.signal})
			.then(({data}) => {
				setData(data)
				setIsLoading(false)
			})
			.catch((e) => console.error(e))
		return () => controller.abort()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url])

	return (
		<ParamsContext.Provider value={{params, setParams}}>
			<FetcherContext.Provider
				value={{setPage, ...(data || initial), isLoading}}
			>
				{children}
			</FetcherContext.Provider>
		</ParamsContext.Provider>
	)
}
