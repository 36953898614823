'use client'

import {CMSMainNews_itemChannel} from 'cms-types'
import {Link} from 'layout/Link'
import css from './NewsTags.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'

const styles = fromModule(css)

export const NewsTags: React.FC<{
	tags: CMSMainNews_itemChannel['tags']
	newsUrl: string
	className?: string
}> = ({tags, newsUrl, className}) => {
	if (!tags || tags.length === 0) return null

	return (
		<div className={styles.newstags.with(className)()}>
			<p className={styles.newstags.title()}>{nl.news_detail.tags}</p>
			<div className={styles.newstags.items()}>
				{tags.map((tag, i) => (
					<div className={styles.newstags.items.item()} key={i}>
						<Link
							href={`${newsUrl}?tag=${tag}`}
							className={styles.newstags.items.item.tag()}
						>
							{tag}
						</Link>
					</div>
				))}
			</div>
		</div>
	)
}
