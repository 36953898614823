'use client'

import {NewsCard, NewsCardType} from 'components/cards/NewsCard'

import css from './NewsOverview.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const NewsOverview: React.FC<{news: NewsCardType[]}> = ({news}) => {
	if (!news || news.length === 0) return null

	return (
		<div className={styles.newsoverview()}>
			{news.map((item, index) => (
				<div className={styles.newsoverview.item()} key={item.id}>
					<NewsCard {...item} priority={index < 3} />
				</div>
			))}
		</div>
	)
}
