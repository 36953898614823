import {CMSMainBlock} from 'cms-types'
import {HTMLAttributes} from 'react'
import {styler} from 'util/styler/Styler'

const styles = styler('block')

export const Block: React.FC<
	HTMLAttributes<HTMLDivElement> & {type: CMSMainBlock['type']}
> = ({type, children, ...props}) => {
	return (
		<div {...props} className={styles.with(type).with(props.className)()}>
			{children}
		</div>
	)
}
