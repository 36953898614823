import {Link} from 'layout/Link'
import {PersonFunction} from 'server/personfunction'
import css from './Moreinfo.module.scss'
import {formatPhone} from 'util/format'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Moreinfo: React.FC<{
	className?: string
	data: PersonFunction | null
}> = ({className, data}) => {
	if (!data) return null

	const {name, phone, email, function: personFunction} = data

	return (
		<div className={styles.moreinfo.with(className)()}>
			<p>
				<b>Meer info:</b>
			</p>
			{name && <p>{name}</p>}
			{personFunction && <p>{personFunction}</p>}
			{phone && (
				<p>
					<Link href={`tel:${phone}`} className={styles.moreinfo.phone()}>
						{formatPhone(phone)}
					</Link>
				</p>
			)}
			{email && (
				<p>
					<Link href={`mailto:${email}`} className={styles.moreinfo.email()}>
						{email}
					</Link>
				</p>
			)}
		</div>
	)
}
