import {
	categories,
	categoryValues,
	disciplines,
	sortOptionValues
} from './sortColumnValues'

import React from 'react'
import css from './RankingsFilters.module.scss'
import dynamic from 'next/dynamic'
import {filterColumns} from './Rankings'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'

const DynamicRankingsFilter = dynamic(() =>
	import('./RankingsFilter').then((mod) => mod.RankingsFilter)
)

export type Filter = {
	year: number
	isIndoor: boolean
	gender: string
	category?: string
	discipline: string
}
export type FilterValue = string | number | boolean | Date

export type FilterColumn = keyof PossibleFilterValues

export type PossibleFilterValues = {
	year: Array<number>
	isIndoor: Array<boolean>
	gender: Array<string>
	category: Array<string>
	discipline: Array<string>
}

const styles = fromModule(css)

const filterLabels = {
	year: 'Jaar',
	isIndoor: 'Indoor/outdoor',
	category: 'Categorie',
	discipline: 'Discipline'
}

export const RankingsFilters: React.FC<{
	filters: Partial<Filter>
	submitFilters: (filters: Filter) => void
	filterValues: PossibleFilterValues
	submitForm: () => void
	resetForm: () => void
	updateUrlSearchParams: (params) => void
}> = ({
	filters,
	submitFilters,
	filterValues,
	submitForm,
	resetForm,
	updateUrlSearchParams
}) => {
	const handleChange = (filter: keyof Filter, value: FilterValue) => {
		let newFilters = {...filters}
		const newValue = getOptionValue(filter, value)
		if (value === '') {
			delete newFilters[filter]
			if (filter === 'category') delete newFilters.gender
		} else if (filter === 'category') {
			if (newValue === 'Mannen' || newValue === 'Vrouwen') {
				delete newFilters.category
				newFilters = {
					...newFilters,
					gender: newValue
				}
			} else {
				delete newFilters.gender
				newFilters = {
					...newFilters,
					category: newValue as string
				}
			}
		} else {
			newFilters = {
				...newFilters,
				[filter]: newValue
			}
		}
		updateUrlSearchParams({})
		submitFilters(newFilters as Filter)
	}

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		submitForm()
	}

	// Retrieve option labels
	const getOptionValues = (filter: FilterColumn) => {
		const filterOptionValues = filterValues[filter]
		// If category 'Senior' is included add respectively 'Mannen' or 'Vrouwen'
		if (filter === 'category') {
			if (
				(filterOptionValues as string[]).includes('Senior M') &&
				(filterOptionValues as string[]).indexOf('Mannen') === -1
			)
				(filterOptionValues as string[]).push('Mannen')
			if (
				(filterOptionValues as string[]).includes('Senior V') &&
				(filterOptionValues as string[]).indexOf('Vrouwen') === -1
			)
				(filterOptionValues as string[]).push('Vrouwen')
		}

		return (
			filterOptionValues
				.map((item) => getOptionLabel(filter, item))
				// Filter values for 'discipline'
				.filter((item) =>
					filter === 'discipline'
						? Object.keys(disciplines).includes(item as string)
						: item
				)
		)
	}

	return (
		<form className={styles.filters()} onSubmit={handleSubmit}>
			<div className={styles.filters.inputs()}>
				{filterColumns
					.filter((col) => col !== 'gender')
					.map((filter: FilterColumn) => {
						const optionValues = getOptionValues(filter)
						return (
							<DynamicRankingsFilter
								key={filter}
								label={filterLabels[filter]}
								value={getOptionLabel(filter, filters[filter], filters.gender)}
								options={sortOptionValues(filter, optionValues)}
								onChange={(value) => handleChange(filter, value)}
								required={true}
							/>
						)
					})}
			</div>
			<div className={styles.filters.buttons()}>
				<button
					className={styles.filters.buttons.reset()}
					type="reset"
					onClick={resetForm}
				>
					{nl.rankings.reset}
				</button>
				<button className={styles.filters.buttons.submit()} type="submit">
					{nl.rankings.submit}
				</button>
			</div>
		</form>
	)
}

const getOptionLabel = (
	filter: keyof Filter,
	option: FilterValue | undefined,
	genderOption?: string
): string | number => {
	if (filter === 'isIndoor')
		return option === true
			? 'Indoor'
			: option === false
			? 'Outdoor'
			: (option as string)
	if (filter === 'category') {
		return (
			categories[genderOption || (option as keyof typeof categories)]?.label ||
			(option as string)
		)
	}
	return option as string
}

const getOptionValue = (filter: keyof Filter, value: FilterValue) => {
	if (filter === 'isIndoor') return value === 'Indoor' ? true : false
	if (filter === 'category') return categoryValues[value as string]
	return value
}
