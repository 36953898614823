import {Filter} from './RankingsFilters'
import React from 'react'
import ReactPaginate from 'react-paginate'
import {categories} from './sortColumnValues'
import css from './RankingsResults.module.scss'
import {formatResultValue} from './formatResultValue'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'
import {useQuery} from '@tanstack/react-query'

export interface Result {
	id: number
	date: Date
	discipline: string
	member: string
	birthyear: number
	relay: number
	score: number
	timeInt: number
	time: string
	wind: number
	category: string
	masterCategory: string
	event: string
	eventNumber: string
	city: string
	club: string
	clubNumber: number
	details: string
	place: number
	isRelevant: boolean
	isHomologized: boolean
	isIndoor: boolean
	year: number
	importMeasure: string
	personalBest: boolean
	seasonalBest: boolean
	seasonalBestIndoor: boolean
	seasonalBestOutdoor: boolean
	relayMembers: string
	notParticipated: boolean
	notArrived: boolean
	noValidPerformance: boolean
	disqualified: boolean
	startNumber: number
	lastUpdatedOn: Date
	lifeNumber: string
	gender: string
}

const styles = fromModule(css)

interface Results {
	count?: number
	result?: Result[]
}

export const RankingsResults: React.FC<{
	filters: Partial<Filter>
	page: number
	resultsPerPage: number
	isLoading: boolean
	updatePage: (page: number) => void
	setIsLoading: (isLoading: boolean) => void
}> = ({filters, page, resultsPerPage, isLoading, updatePage, setIsLoading}) => {
	// Fetch paginated results using selected filters
	const {data} = useQuery(
		['results', filters, page],
		async () => {
			if (Object.keys(filters).length === 0) return
			const response = await fetch('/api/results', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({filters, page: page > 0 && page, resultsPerPage})
			})
			const data: Results = await response.json()
			const pageCount: number =
				(data.count && Math.ceil(data.count / resultsPerPage)) || 0
			if (page > pageCount) updatePage(1)
			setIsLoading(false)
			return {...data, pageCount, currentPage: page}
		},
		{keepPreviousData: true}
	)

	if (isLoading)
		return (
			<div className={styles.results()}>
				<div className={styles.results.container()}>
					<span className={styles.results.container.loader()} />
				</div>
			</div>
		)
	if (!data || !data.result) return null

	return (
		<div className={styles.results()}>
			<ResultsTitle filters={filters} />
			{data.count === 0 ? (
				<p>{nl.rankings.no_results}</p>
			) : (
				<>
					<ResultsTable
						discipline={filters.discipline || ''}
						results={data.result}
						page={data.currentPage}
						resultsPerPage={resultsPerPage}
					/>
					<ResultsPagination
						page={data.currentPage}
						pageCount={data.pageCount}
						handlePageClick={updatePage}
					/>
				</>
			)}
		</div>
	)
}

const ResultsTitle: React.FC<{
	filters: Partial<Filter>
}> = ({filters}) => (
	<h3 className={styles.title()}>
		{nl.rankings.ranking} {filters.year} - {filters.discipline},{' '}
		{(filters.category && categories[filters.category]?.label) ||
			filters.gender}
	</h3>
)

const ResultsTable: React.FC<{
	discipline: string
	results: Result[]
	page: number
	resultsPerPage: number
}> = ({discipline, results, page, resultsPerPage}) => (
	<div className={styles.table()}>
		<table>
			<thead>
				<tr>
					<th>{nl.rankings.result.number}</th>
					<th>{nl.rankings.result.performance}</th>
					<th>{nl.rankings.result.wind}</th>
					<th>{nl.rankings.result.name}</th>
					<th>{nl.rankings.result.club}</th>
					<th>{nl.rankings.result.contest}</th>
					<th>{nl.rankings.result.city}</th>
					<th>{nl.rankings.result.date}</th>
				</tr>
			</thead>
			<tbody>
				{results.map((result, index) => (
					<tr key={result.id}>
						<td>{index + 1 + resultsPerPage * (page - 1)}</td>
						<td>{formatResultValue(discipline, result.score, result.time)}</td>
						<td>{result.wind}</td>
						<td>{result.member}</td>
						<td>{result.club}</td>
						<td>{result.event}</td>
						<td>{result.city}</td>
						<td>
							{new Date(result.date).toLocaleDateString('nl-BE', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit'
							})}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</div>
)

const ResultsPagination: React.FC<{
	page: number
	pageCount: number
	handlePageClick: (page: number) => void
}> = ({page, pageCount, handlePageClick}) => (
	<ReactPaginate
		onPageChange={(e) => handlePageClick(e.selected + 1)}
		pageRangeDisplayed={3}
		marginPagesDisplayed={2}
		pageCount={pageCount}
		nextLabel={<ResultsLabel label="→" />}
		previousLabel={<ResultsLabel label="←" />}
		disabledClassName={styles.pagination.button.is({disabled: true})()}
		pageClassName={styles.pagination.page()}
		pageLinkClassName={styles.pagination.page.link()}
		previousClassName={styles.pagination.button()}
		previousLinkClassName={styles.pagination.prev()}
		nextClassName={styles.pagination.button()}
		nextLinkClassName={styles.pagination.next()}
		breakLabel="..."
		breakClassName={styles.pagination.page()}
		breakLinkClassName={styles.pagination.page.link()}
		containerClassName={styles.pagination.wrapper()}
		activeClassName={styles.pagination.page.is({
			active: true
		})()}
		renderOnZeroPageCount={() => null}
		forcePage={page - 1 || 0}
	/>
)

const ResultsLabel: React.FC<{label: string}> = ({label}) => (
	<div className={styles.label()}>
		<span>{label}</span>
	</div>
)
