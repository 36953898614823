import {disciplines} from './sortColumnValues'

export const formatResultValue = (
	discipline: string,
	score: number,
	time: string
) => {
	const format = disciplines[discipline]?.format

	switch (format) {
		case '[M]M:SS.XX':
			return minutes(time)
		case '[HH:]MM:SS.XX':
			return hours(time)
		case 'SS.XX':
			return seconds(time)
		case '[D]D.dd':
			return (Math.round(score * 100) / 100).toFixed(2)
		case 'Integer':
			return Math.floor(score)
		case 'Long distance 3 decimals':
			return Math.round(score) / 1000 + ' km'
		case 'Long distance 2 decimals':
			return thousandSeparator(Math.round(score * 100) / 100) + ' m'
		case 'Long distance':
			return thousandSeparator(score) + ' m'
		default:
			return score || time
	}
}

function minutes(x: string) {
	const minutes = x.slice(3)
	if (minutes.startsWith('0')) return minutes.slice(1)
	return minutes
}

function hours(x: string) {
	let hours = x.startsWith('0') ? x.slice(1) : x
	if (hours.startsWith('0')) hours = hours.slice(2)
	if (hours.startsWith('0')) hours = hours.slice(1)
	return hours
}

function seconds(x: string) {
	const minutes = x.slice(3, 5)
	let seconds = Number(x.slice(6, 8))
	if (minutes !== '00') seconds += Number(minutes) * 60
	return seconds + x.slice(8)
}

function thousandSeparator(x: number) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
