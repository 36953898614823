import {CMSDate, CMSImage} from 'cms-types'

import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import React from 'react'
import {Title} from 'layout/Title'
import {Variant} from 'util/styler'
import css from './NewsCard.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getDayMonth} from 'util/dateformat'

const styles = fromModule(css)

export type NewsCardType = {
	id: number
	url: string
	title: string
	date?: CMSDate
	img?: CMSImage
	mod?: Variant<'featured'>
	priority?: boolean
	show_home_header?: boolean
}

export const NewsCard: React.FC<NewsCardType> = ({
	url,
	title,
	date,
	img,
	mod,
	priority
}) => {
	if (!url && !title) return null

	return (
		<Link href={url} className={styles.newscard.mod(mod)()}>
			<div className={styles.newscard.image()}>
				{img?.src && (
					<Image
						{...img}
						priority={priority ? true : false}
						fill
						sizes={
							mod === 'featured'
								? '(max-width: 1024px) 100vw, 720px'
								: '(max-width: 600px) 100vw, (max-width: 768px) 50vw, (max-width: 1024px) 33.33vw, (max-width: 1200px) 347px, 347px'
						}
						className={styles.newscard.image.bg()}
						withLoader={true}
					/>
				)}
			</div>
			<div className={styles.newscard.content()}>
				{date && (
					<p className={styles.newscard.content.date()}>{getDayMonth(date)}</p>
				)}
				<Title.H4
					as="h3"
					mod={['uppercase']}
					className={styles.newscard.content.title()}
				>
					{title}
				</Title.H4>
			</div>
		</Link>
	)
}
