import {Link, LinkProps} from './Link'

import React from 'react'
import {Variant} from 'util/styler'
import css from './LinkStyled.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const LinkStyled: React.FC<
	LinkProps & {
		mod?: Variant<'inherit' | 'regular'>
	}
> = ({mod, ...props}) => {
	return (
		<div className={styles.linkstyled.with(props.className)()}>
			<Link
				{...props}
				tabIndex={0}
				className={styles.linkstyled.link.mod(mod)()}
			>
				<LinkStyledText>{props.children}</LinkStyledText>
			</Link>
		</div>
	)
}

const LinkStyledText: React.FC<{children?: React.ReactNode}> = ({children}) => (
	<span className={styles.text()}>
		<span className={styles.text.border()}>{children}</span>
	</span>
)
