import {ServerComponent} from 'util/ServerComponent'
import css from './CalendarMonth.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getMonthYear} from 'util/dateformat'

const styles = fromModule(css)

export const CalendarMonth: ServerComponent<{
	theme: string | null
	month: number | null
	isPast: boolean
}> = ({month, isPast, theme}) => {
	if (!month) return null

	return (
		<div
			className={styles.calendarmonth.is({past: isPast})()}
			data-theme={theme}
		>
			{getMonthYear(month)}
		</div>
	)
}
