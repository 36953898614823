export const extendedTargetAudienceOptions = {
	competitietrainers_trainer_a: 'Competitietrainers (Trainer A)',
	competitietrainers_trainer_b: 'Competitietrainers (Trainer B)',
	competitietrainers_trainer_c: 'Competitietrainers (Trainer C)',
	recreatietrainers_corunner: 'Recreatietrainers (Co-Runner)',
	recreatietrainers_gatletiek: 'Recreatietrainers (Begeleider G-atletiek)',
	recreatietrainers_initiator_running: 'Recreatietrainers (Initiator Running)',
	recreatietrainers_instructeur_running:
		'Recreatietrainers (Instructeur Running)',
	jeugdtrainers_start2coach: 'Jeugdtrainers (Start 2 coach)',
	jeugdtrainers_initiator_atletiek: 'Jeugdtrainers (Initiator Atletiek)',
	jeugdtrainers_instructeur_b_atletiek:
		'Jeugdtrainers (Instructeur B Atletiek)',
	jeugdtrainers_initiator_atletiek_jeugd:
		'Jeugdtrainers (Initiator Atletiek Jeugd)',
	jury: 'Jury'
}
