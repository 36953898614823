import {Filter} from './RankingsFilters'

export const sortOptionValues = (filter: keyof Filter, values) => {
	const sortedValues = values.sort((a, b) => {
		switch (filter) {
			case 'year':
				return b - a
			case 'isIndoor':
				if (a < b) return -1
				if (a > b) return 1
			case 'gender':
				if (a < b) return -1
				if (a > b) return 1
			case 'category':
				if (categoryOrder[a] < categoryOrder[b]) return -1
				if (categoryOrder[a] > categoryOrder[b]) return 1
			case 'discipline':
				if (disciplines[a]?.order < disciplines[b]?.order) return -1
				if (disciplines[a]?.order > disciplines[b]?.order) return 1
			default:
				return 0
		}
	})

	return sortedValues
}

export const categories = {
	Mannen: {order: 1, label: 'Alle categorieën mannen'},
	Vrouwen: {order: 2, label: 'Alle categorieën vrouwen'},
	'Masters Mannen': {order: 3, label: 'Masters Mannen'},
	'Masters Vrouwen': {order: 4, label: 'Masters Vrouwen'},
	'Senior M': {order: 5, label: 'Senioren Mannen'},
	'Senior V': {order: 6, label: 'Senioren Vrouwen'},
	'Junior M': {order: 7, label: 'Junioren Mannen'},
	'Junior V': {order: 8, label: 'Junioren Vrouwen'},
	'Scholier M': {order: 9, label: 'Scholieren Jongens'},
	'Scholier V': {order: 10, label: 'Scholieren Meisjes'},
	'Cadet M': {order: 11, label: 'Cadetten Jongens'},
	'Cadet V': {order: 12, label: 'Cadetten Meisjes'},
	'Miniem M': {order: 13, label: 'Miniemen Jongens'},
	'Miniem V': {order: 14, label: 'Miniemen Meisjes'},
	'Pupil M': {order: 15, label: 'Pupillen Jongens'},
	'Pupil V': {order: 16, label: 'Pupillen Meisjes'}
} as const

const categoryOrder = Object.fromEntries(
	Object.values(categories).map((value) => [value.label, value.order])
)

export const categoryValues = Object.fromEntries(
	Object.entries(categories).map(([key, value]) => [value.label, key])
)

export const disciplines = {
	'50m': {order: 1, format: 'SS.XX'},
	'60m': {order: 2, format: 'SS.XX'},
	'80m': {order: 3, format: 'SS.XX'},
	'100m': {order: 4, format: 'SS.XX'},
	'150m': {order: 5, format: 'SS.XX'},
	'200m': {order: 6, format: 'SS.XX'},
	'300m': {order: 7, format: 'SS.XX'},
	'400m': {order: 8, format: '[M]M:SS.XX'},
	'600m': {order: 9, format: '[M]M:SS.XX'},
	'800m': {order: 10, format: '[M]M:SS.XX'},
	'1000m': {order: 11, format: '[M]M:SS.XX'},
	'1500m': {order: 12, format: '[M]M:SS.XX'},
	mijl: {order: 13, format: '[M]M:SS.XX'},
	'2000m': {order: 14, format: '[M]M:SS.XX'},
	'3000m': {
		order: 15,
		format: '[HH:]MM:SS.XX'
	},
	'2 mijl': {order: 16, format: '[M]M:SS.XX'},
	'5000m': {
		order: 17,
		format: '[HH:]MM:SS.XX'
	},
	'10.000 meter': {
		order: 18,
		format: '[HH:]MM:SS.XX'
	},
	'20.000 meter': {
		order: 19,
		format: '[HH:]MM:SS.XX'
	},
	'25.000 meter': {
		order: 20,
		format: '[HH:]MM:SS.XX'
	},
	'30.000 meter': {
		order: 21,
		format: '[HH:]MM:SS.XX'
	},
	marathon: {
		order: 22,
		format: '[HH:]MM:SS.XX'
	},
	uurloop: {order: 23, format: 'Long distance'},
	'1 km snelwandelen': {
		order: 24,
		format: '[HH:]MM:SS.XX'
	},
	'10 km snelwandelen': {
		order: 25,
		format: '[HH:]MM:SS.XX'
	},
	'50m H (0,762m) - Cad/W40-45': {
		order: 26,
		format: 'SS.XX'
	},
	'50m horden 0,838m': {order: 27, format: 'SS.XX'},
	'50m H (0,914m) - Cad/M50-55': {
		order: 28,
		format: 'SS.XX'
	},
	'50m horden 1,000m': {order: 29, format: 'SS.XX'},
	'50m H (1,067m)': {order: 30, format: 'SS.XX'},
	'60m horden 0,686': {order: 31, format: 'SS.XX'},
	'60m H (0,686m) - M80+': {order: 32, format: 'SS.XX'},
	'60m H (0,762m)': {order: 33, format: 'SS.XX'},
	'60m Horden 0,762m/5H 12/7/20 W50..55': {
		order: 34,
		format: 'SS.XX'
	},
	'60m horden 0,838m': {order: 35, format: 'SS.XX'},
	'60m horden 0,838m (cad)': {
		order: 36,
		format: 'SS.XX'
	},
	'60m H (0,914m) - Cad/M50-55': {
		order: 37,
		format: 'SS.XX'
	},
	'60m H (0,990m) - Jun/M35-45': {
		order: 38,
		format: 'SS.XX'
	},
	'60m H (1,067m)': {order: 39, format: 'SS.XX'},
	'60m horden 0,686m W60+': {
		order: 40,
		format: 'SS.XX'
	},
	'60m horden 0,68m/6H': {order: 41, format: 'SS.XX'},
	'60m horden 0,762m/6H': {order: 42, format: 'SS.XX'},
	'80m H (0,686m) - W60+': {order: 43, format: 'SS.XX'},
	'80m horden 0,762m W50-55': {
		order: 44,
		format: 'SS.XX'
	},
	'80m horden 0,762m M70-75': {
		order: 45,
		format: 'SS.XX'
	},
	'80m horden 0,686m M80+': {
		order: 46,
		format: 'SS.XX'
	},
	'80m horden 0,76m': {order: 47, format: 'SS.XX'},
	'80m horden 0,762m': {order: 48, format: 'SS.XX'},
	'80m horden 0,838m': {order: 49, format: 'SS.XX'},
	'100m H (0,762m)': {order: 50, format: 'SS.XX'},
	'100m horden 0,838m (cad)': {
		order: 51,
		format: 'SS.XX'
	},
	'100m horden 0,838m M60-65': {
		order: 52,
		format: 'SS.XX'
	},
	'100m horden 0,840': {order: 53, format: 'SS.XX'},
	'100m H (0,914m)': {order: 54, format: 'SS.XX'},
	'110m H (0,914m)': {order: 55, format: 'SS.XX'},
	'110m H (0,990m)': {order: 56, format: 'SS.XX'},
	'110m H (1,067m)': {order: 57, format: 'SS.XX'},
	'150m horden 0,686': {order: 58, format: 'SS.XX'},
	'150m H (0,762m)': {order: 59, format: 'SS.XX'},
	'200m horden 0,686m': {order: 60, format: 'SS.XX'},
	'200m H (0,762m)': {order: 61, format: 'SS.XX'},
	'300m H (0,686m) - M70+ W60+': {
		order: 62,
		format: 'SS.XX'
	},
	'300m H (0,762m)': {order: 63, format: 'SS.XX'},
	'300m horden 0,838m': {order: 64, format: 'SS.XX'},
	'400m H (0,762m)': {order: 65, format: 'SS.XX'},
	'400m horden 0,838m': {order: 66, format: 'SS.XX'},
	'400m H (0,914m)': {order: 67, format: 'SS.XX'},
	'1500m Steeple (0,762m)': {
		order: 68,
		format: '[HH:]MM:SS.XX'
	},
	'2000m Steeple (0,762m)': {
		order: 69,
		format: '[HH:]MM:SS.XX'
	},
	'2000m steeple 0,762m M60+': {
		order: 70,
		format: '[HH:]MM:SS.XX'
	},
	'2000m steeple 0,838m': {
		order: 71,
		format: '[M]M:SS.XX'
	},
	'2000m steeple 0,914m': {
		order: 72,
		format: '[HH:]MM:SS.XX'
	},
	'3000m Steeple (0,762m)': {
		order: 73,
		format: '[HH:]MM:SS.XX'
	},
	'3000m Steeple (0,914m)': {
		order: 74,
		format: '[HH:]MM:SS.XX'
	},
	'discuswerpen (600g)': {order: 75, format: '[D]D.dd'},
	'discuswerpen (750g)': {order: 76, format: '[D]D.dd'},
	'discuswerpen 0,750kg W75+': {
		order: 77,
		format: '[D]D.dd'
	},
	'discuswerpen (1kg)': {order: 78, format: '[D]D.dd'},
	'discuswerpen (1,5kg)': {
		order: 79,
		format: '[D]D.dd'
	},
	'discuswerpen (1,75kg)': {
		order: 80,
		format: '[D]D.dd'
	},
	'discuswerpen (2kg)': {order: 81, format: '[D]D.dd'},
	'gewichtw. (4kg) - W75+': {
		order: 82,
		format: '[D]D.dd'
	},
	'gewichtw. (5,45kg) - W60-70': {
		order: 83,
		format: '[D]D.dd'
	},
	'gewichtw. (5,45kg) - M80+': {
		order: 84,
		format: '[D]D.dd'
	},
	'gewichtw. (7,26kg) - M70-75': {
		order: 85,
		format: '[D]D.dd'
	},
	'gewichtw. (7,26kg) - W50-55': {
		order: 86,
		format: '[D]D.dd'
	},
	'gewichtw. (9,08kg) - M60-65': {
		order: 87,
		format: '[D]D.dd'
	},
	'gewichtw. (9,08kg)': {order: 88, format: '[D]D.dd'},
	'gewichtwerpen 09,08kg': {
		order: 89,
		format: '[D]D.dd'
	},
	'gewichtwerpen 11,34kg': {
		order: 90,
		format: '[D]D.dd'
	},
	'gewichtw. (11,34kg) - M50-55': {
		order: 91,
		format: '[D]D.dd'
	},
	'gewichtw. (15,88kg)': {order: 92, format: '[D]D.dd'},
	'hamerslingeren (2kg)': {
		order: 93,
		format: '[D]D.dd'
	},
	'hamerslingeren (3kg)': {
		order: 94,
		format: '[D]D.dd'
	},
	'hamerslingeren (4kg)': {
		order: 95,
		format: '[D]D.dd'
	},
	'hamerslingeren (5kg)': {
		order: 96,
		format: '[D]D.dd'
	},
	'hamerslingeren (6kg)': {
		order: 97,
		format: '[D]D.dd'
	},
	'hamerslingeren (7,260kg)': {
		order: 98,
		format: '[D]D.dd'
	},
	'kogelstoten (1kg)': {order: 99, format: '[D]D.dd'},
	'kogelstoten (2kg)': {order: 100, format: '[D]D.dd'},
	'kogelstoten (3kg)': {order: 101, format: '[D]D.dd'},
	'kogel (3kg) M80+': {order: 102, format: '[D]D.dd'},
	'kogelstoten (4kg)': {order: 103, format: '[D]D.dd'},
	'kogelstoten (5kg)': {order: 104, format: '[D]D.dd'},
	'kogelstoten (6kg)': {order: 105, format: '[D]D.dd'},
	'kogelstoten (7,260kg)': {
		order: 106,
		format: '[D]D.dd'
	},
	hockeybalwerpen: {order: 107, format: '[D]D.dd'},
	'speerwerpen (400g)': {order: 108, format: '[D]D.dd'},
	'speerwerpen (500g)': {order: 109, format: '[D]D.dd'},
	'speerwerpen (600g)': {order: 110, format: '[D]D.dd'},
	'speerwerpen (700g)': {order: 111, format: '[D]D.dd'},
	'speerwerpen (800g)': {order: 112, format: '[D]D.dd'},
	Vortexwerpen: {order: 113, format: '[D]D.dd'},
	verspringen: {order: 114, format: '[D]D.dd'},
	hoogspringen: {order: 115, format: '[D]D.dd'},
	hinkstapspringen: {order: 116, format: '[D]D.dd'},
	polsstokspringen: {order: 117, format: '[D]D.dd'},
	driekamp: {order: 118, format: 'Integer'},
	vierkamp: {order: 119, format: 'Integer'},
	'vijfkamp (cad-m)': {order: 120, format: 'Integer'},
	'vijfkamp (cad vr)': {order: 121, format: 'Integer'},
	'vijfkamp Cad-M O': {order: 122, format: 'Integer'},
	'vijfkamp Sch-V O': {order: 123, format: 'Integer'},
	'vijfkamp Sch-V I': {order: 124, format: 'Integer'},
	'vijfkamp Sch-M O': {order: 125, format: 'Integer'},
	'vijfkamp (jun)': {order: 126, format: 'Integer'},
	'vijfkamp Sen-M/M35-45 O': {order: 127, format: 'Integer'},
	'vijfkamp M35-45 I': {order: 128, format: 'Integer'},
	'vijfkamp M50-55 O': {order: 129, format: 'Integer'},
	'vijfkamp M60-65 O': {order: 130, format: 'Integer'},
	'vijfkamp (M70+)': {order: 131, format: 'Integer'},
	'vijfkamp (min)': {order: 132, format: 'Integer'},
	'vijfkamp W40-45 O': {order: 133, format: 'Integer'},
	'vijfkamp W60-70 O': {order: 134, format: 'Integer'},
	'vijfkamp W50-55': {order: 135, format: 'Integer'},
	'vijfkamp W60-70 I': {order: 136, format: 'Integer'},
	'vijfkamp W75+': {order: 137, format: 'Integer'},
	'vijfkamp W75+ O': {order: 138, format: 'Integer'},
	'vijfkamp M50-55': {order: 139, format: 'Integer'},
	'vijfkamp M60-65': {order: 140, format: 'Integer'},
	'vijfkamp M70-75': {order: 141, format: 'Integer'},
	'vijfkamp M80': {order: 142, format: 'Integer'},
	'vijfkamp M80+ O': {order: 143, format: 'Integer'},
	'vijfkamp W40-45 I': {order: 144, format: 'Integer'},
	'zeskamp Cad-M I': {order: 145, format: 'Integer'},
	'zeskamp Cad-V O': {order: 146, format: 'Integer'},
	'zevenkamp Sch-V O': {order: 147, format: 'Integer'},
	'zevenkamp Sch-M I': {order: 148, format: 'Integer'},
	'zevenkamp (sch)': {order: 149, format: 'Integer'},
	'zevenkamp Jun-M I': {order: 150, format: 'Integer'},
	'zevenkamp JSM-V O': {order: 151, format: 'Integer'},
	'zevenkamp Sen-M I': {order: 152, format: 'Integer'},
	'zevenkamp W40-45 O': {order: 153, format: 'Integer'},
	'zevenkamp W50-55 O': {order: 154, format: 'Integer'},
	'zevenkamp W60-65-70 O': {order: 155, format: 'Integer'},
	'zevenkamp W75+ O': {order: 156, format: 'Integer'},
	'achtkamp Cad-M O': {order: 157, format: 'Integer'},
	negenkamp: {order: 158, format: 'Integer'},
	'tienkamp Sch-M O': {order: 159, format: 'Integer'},
	'tienkamp Jun-M O': {order: 160, format: 'Integer'},
	'tienkamp Jun-V O': {order: 161, format: 'Integer'},
	'tienkamp JSM-V O': {order: 162, format: 'Integer'},
	'tienkamp Sen-M O': {order: 163, format: 'Integer'},
	'tienkamp W40-45': {order: 164, format: 'Integer'},
	'tienkamp W75+': {order: 165, format: 'Integer'},
	'tienkamp M70-75': {order: 166, format: 'Integer'},
	'tienkamp W50-55': {order: 167, format: 'Integer'},
	'tienkamp W60-65': {order: 168, format: 'Integer'},
	'tienkamp M60-65': {order: 169, format: 'Integer'},
	'tienkamp M35-45': {order: 170, format: 'Integer'},
	'tienkamp M50-55': {order: 171, format: 'Integer'},
	'tienkamp M80+': {order: 172, format: 'Integer'},
	werpvijfkamp: {order: 173, format: 'Integer'},
	'Werpvijfkamp Jun V': {order: 174, format: 'Integer'},
	'Werpvijfkamp Jun M': {order: 175, format: 'Integer'},
	'werpvijfkamp M50-55': {order: 176, format: 'Integer'},
	'werpvijfkamp M60-65': {order: 177, format: 'Integer'},
	'werpvijfkamp M70-75': {order: 178, format: 'Integer'},
	'werpvijfkamp M80+': {order: 179, format: 'Integer'},
	'werpvijfkamp V': {order: 180, format: 'Integer'},
	'werpvijfkamp W50-55': {order: 181, format: 'Integer'},
	'werpvijfkamp W60...70': {order: 182, format: 'Integer'},
	'werpvijfkamp W75+': {order: 183, format: 'Integer'},
	'60m frame runner': {order: 184, format: 'SS.XX'},
	'100m frame runner M': {order: 185, format: 'SS.XX'},
	'100m frame runner V': {order: 186, format: 'SS.XX'},
	'400m frame runner': {
		order: 187,
		format: '[M]M:SS.XX'
	},
	'60m Wheelers': {order: 188, format: 'SS.XX'},
	'100m wheelers M': {order: 189, format: 'SS.XX'},
	'100m wheelers V': {order: 190, format: 'SS.XX'},
	'200m wheelers M': {order: 191, format: 'SS.XX'},
	'200m wheelers V': {order: 192, format: 'SS.XX'},
	'400m wheelers M': {
		order: 193,
		format: '[M]M:SS.XX'
	},
	'400m wheelers V': {
		order: 194,
		format: '[M]M:SS.XX'
	},
	'800m wheelers M': {
		order: 195,
		format: '[M]M:SS.XX'
	},
	'800m wheelers V': {
		order: 196,
		format: '[M]M:SS.XX'
	},
	'1500m wheelers M': {
		order: 197,
		format: '[M]M:SS.XX'
	},
	'discus seated 1kg M': {
		order: 198,
		format: '[D]D.dd'
	},
	'discus seated 1kg V': {
		order: 199,
		format: '[D]D.dd'
	},
	'kogel seated M': {order: 200, format: '[D]D.dd'},
	'kogel seated V': {order: 201, format: '[D]D.dd'},
	'speer seated 600g M': {
		order: 202,
		format: '[D]D.dd'
	},
	'speer seated 600g V': {
		order: 203,
		format: '[D]D.dd'
	},
	'150 meter cad M': {order: 204, format: 'SS.XX'},
	'150 meter cad V': {order: 205, format: 'SS.XX'},
	'150 meter sch V': {order: 206, format: 'SS.XX'},
	'150 meter sch M': {order: 207, format: 'SS.XX'},
	'150 meter jun V': {order: 208, format: 'SS.XX'},
	'150 meter jun M': {order: 209, format: 'SS.XX'},
	'150 meter sen V': {order: 210, format: 'SS.XX'},
	'150 meter sen M': {order: 211, format: 'SS.XX'},
	'150 meter masters V': {order: 212, format: 'SS.XX'},
	'150 meter masters M': {order: 213, format: 'SS.XX'}
} as const
