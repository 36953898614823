import {CMSMainChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {Fragment} from 'react'
import css from './Breadcrumbs.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Breadcrumbs: React.FC<{
	title: string
	parents?: CMSMainChannel['parents']
}> = ({title, parents}) => {
	if (!parents || parents.length === 0) return null

	return (
		<div className={styles.breadcrumbs()}>
			<Container>
				{parents.reverse().map((parent, i) => (
					<Fragment key={i}>
						<a href={parent.url} className={styles.breadcrumbs.parent()}>
							{parent.title}
						</a>
						<span className={styles.breadcrumbs.divider()}>/</span>
					</Fragment>
				))}
				<p className={styles.breadcrumbs.title()}>{title}</p>
			</Container>
		</div>
	)
}
