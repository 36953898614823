'use client'

import NextImage, {ImageProps} from 'next/image'
import {CSSProperties, useState} from 'react'

import {CMSImage} from 'cms-types'
import {ImageLoader} from 'components/imageloader/ImageLoader'
import {fromModule} from 'util/styler/Styler'
import css from './Image.module.scss'

const path = require('path')
const styles = fromModule(css)

const generateSrcSet = (src: string): string => {
	const sizes: number[] = [
		347, 640, 720, 750, 828, 1080, 1200, 1920, 2048, 3840
	]
	const quality: number = 75
	return sizes
		.map(
			(size) =>
				`/_next/image?url=${encodeURIComponent(
					src
				)}&w=${size}&q=${quality} ${size}w`
		)
		.join(', ')
}

export const Image: React.FC<
	CMSImage &
		ImageProps & {
			sizes: ImageProps['sizes']
			objectfit?: CSSProperties['objectFit']
			className?: string
		} & {withLoader?: boolean}
> = ({className, focus, meta, objectfit, size, withLoader, ...props}) => {
	const [imgLoaded, setImgLoaded] = useState<boolean>(false)

	if (!props?.src) return null
	const fileName = path.parse(props.title).name

	if (props.fill) {
		const objectFit = objectfit
			? objectfit
			: props.height > props.width * 2
			? 'contain'
			: 'cover'
		const objectPosition = focus && `${focus.x * 100}% ${focus.y * 100}%`

		return (
			<>
				{withLoader && !imgLoaded && <ImageLoader />}
				<NextImage
					{...props}
					width={undefined}
					height={undefined}
					style={props.style || {objectFit, objectPosition}}
					title={undefined}
					alt={props.alt || fileName}
					onLoad={() => setImgLoaded(true)}
					className={styles.image.with(className).is({loaded: imgLoaded})()}
				/>
				{props.priority ? (
					<link
						rel="preload"
						imageSrcSet={generateSrcSet(props.src)}
						as="image"
						imageSizes={props.sizes}
					/>
				) : null}
			</>
		)
	}

	return (
		<>
			<NextImage
				{...props}
				title={undefined}
				alt={props.alt || fileName}
				onLoad={() => setImgLoaded(true)}
				className={styles.image.with(className).is({loaded: imgLoaded})()}
			/>
			{props.priority ? (
				<link
					rel="preload"
					imageSrcSet={generateSrcSet(props.src)}
					as="image"
					imageSizes={props.sizes}
				/>
			) : null}
		</>
	)
}
