import React, {HTMLAttributes, PropsWithChildren} from 'react'

import {Container} from 'layout/Container'
import css from './Contentside.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Contentside: React.FC<{
	children: React.ReactNode
	className?: string
}> = ({children, className}) => {
	return (
		<div className={`contentside ${styles.contentside.with(className)()}`}>
			<Container>
				<div className={styles.contentside.row()}>{children}</div>
			</Container>
		</div>
	)
}

export const ContentsideContent: React.FC<{children: React.ReactNode}> = ({
	children
}) => <div className={styles.content()}>{children}</div>

export const ContentsideSidebar: React.FC<
	PropsWithChildren<HTMLAttributes<HTMLDivElement>>
> = (props) => <aside {...props} className={styles.sidebar()} />
