import {
	CMSMainEducation_detailChannel,
	CMSMainRefresher_course_detailChannel
} from 'cms-types'
import {endOfDay, isFuture, isValid} from 'date-fns'
import {getDayMonth, getDayOfWeek, getFromToHours} from 'util/dateformat'

import {CalendarItem as CalendarItemType} from 'server/calendar'
import {EducationItem} from 'server/educations'
import {LabelValue} from 'layout/LabelValue'
import {Link} from 'layout/Link'
import {Moreinfo} from 'components/moreinfo/Moreinfo'
import {PersonFunction} from 'server/personfunction'
import {RefreshercourseItem} from 'server/refreshercourses'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/wysiwyg/Wysiwyg'
import css from './CalendarItem.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getSubLabel} from 'util/getsublabel'
import {nl} from 'locale'

const styles = fromModule(css)

type EducationTargetAudience = EducationItem['target_audience']
type RefreshercourseTargetAudience = RefreshercourseItem['target_audience']
type TargetAudience = EducationTargetAudience | RefreshercourseTargetAudience

export function getTheme(
	target_audience: string
): 'competition' | 'recreation' | 'youth' | 'organisation' | 'club' | null {
	switch (target_audience) {
		case 'competitietrainers':
		case 'competitietrainers_trainer_a':
		case 'competitietrainers_trainer_b':
		case 'competitietrainers_trainer_c':
			return 'competition'
		case 'recreatietrainers':
		case 'recreatietrainers_corunner':
		case 'recreatietrainers_gatletiek':
		case 'recreatietrainers_initiator_running':
		case 'recreatietrainers_instructeur_running':
			return 'recreation'
		case 'jeugdtrainers':
		case 'jeugdtrainers_start2coach':
		case 'jeugdtrainers_initiator_atletiek':
		case 'jeugdtrainers_instructeur_b_atletiek':
		case 'jeugdtrainers_initiator_atletiek_jeugd':
			return 'youth'
		case 'jury':
			return 'organisation'
		case 'clubbestuurders':
			return 'club'
	}

	return null
}

function getTargetAudience(
	item: CalendarItem & {
		moreInfo: PersonFunction | null
	}
): string {
	const {channel} = item
	if (channel !== 'education_detail' && channel !== 'refresher_course_detail')
		return ''
	if (!item.target_audience) return ''

	const {target_audience} = item
	if (
		channel === 'refresher_course_detail' &&
		target_audience.length > 1 &&
		(
			target_audience as CMSMainRefresher_course_detailChannel['target_audience']
		).includes('competitietrainers') &&
		(
			target_audience as CMSMainRefresher_course_detailChannel['target_audience']
		).includes('recreatietrainers') &&
		(
			target_audience as CMSMainRefresher_course_detailChannel['target_audience']
		).includes('jeugdtrainers')
	)
		return nl.calendar_item.target_audience.alltrainers
	else if (
		channel === 'education_detail' &&
		target_audience.length > 1 &&
		((
			target_audience as CMSMainEducation_detailChannel['target_audience']
		).includes('competitietrainers_trainer_a') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('competitietrainers_trainer_b') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('competitietrainers_trainer_c')) &&
		((
			target_audience as CMSMainEducation_detailChannel['target_audience']
		).includes('recreatietrainers_corunner') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('recreatietrainers_gatletiek') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('recreatietrainers_initiator_running') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('recreatietrainers_instructeur_running')) &&
		((
			target_audience as CMSMainEducation_detailChannel['target_audience']
		).includes('jeugdtrainers_start2coach') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('jeugdtrainers_initiator_atletiek') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('jeugdtrainers_instructeur_b_atletiek') ||
			(
				target_audience as CMSMainEducation_detailChannel['target_audience']
			).includes('jeugdtrainers_initiator_atletiek_jeugd'))
	)
		return nl.calendar_item.target_audience.alltrainers

	if (target_audience.length > 1)
		return target_audience
			.map((target) =>
				channel === 'education_detail'
					? getSubLabel(nl.calendar_item.target_audience[target])
					: nl.calendar_item.target_audience[target]
			)
			.join(', ')

	return channel === 'education_detail'
		? getSubLabel(nl.calendar_item.target_audience[target_audience[0]])
		: nl.calendar_item.target_audience[target_audience[0]]
}

export type CalendarItem =
	| CalendarItemType
	| EducationItem
	| RefreshercourseItem

const Day: React.FC<{date: string; until?: boolean}> = ({date, until}) => {
	if (!date) return null

	return (
		<>
			{until && <p className={styles.calendaritem.dateblock.until()}>-</p>}
			<div className={styles.calendaritem.dateblock.date()}>
				<p className={styles.calendaritem.dateblock.date.day()}>
					{getDayOfWeek(date)}
				</p>
				<p>{getDayMonth(date)}</p>
			</div>
		</>
	)
}

export const CalendarItem: React.FC<
	CalendarItem & {moreInfo: PersonFunction | null}
> = (item) => {
	const {url, has_detail, date, end_date, title, channel, moreInfo} = item
	const labelValueWidth: number = 96

	if (!date && !title) return null

	const description = channel === 'calendar_item' ? item.description : null
	if (channel === 'calendar_item' && !date && !title && !description)
		return null

	const startTime = getFromToHours(date, end_date)

	const target_audience: string = getTargetAudience(item)
	let organisation:
		| (EducationItem | RefreshercourseItem)['organisation']
		| null = null
	let docent: (EducationItem | RefreshercourseItem)['docent'] | null = null
	let location: (EducationItem | RefreshercourseItem)['location'] | null = null
	let participation:
		| (EducationItem | RefreshercourseItem)['participation']
		| null = null
	let registration:
		| (EducationItem | RefreshercourseItem)['registration']
		| null = null
	let extra: (EducationItem | RefreshercourseItem)['extra'] | null = null
	if (channel === 'education_detail' || channel === 'refresher_course_detail') {
		organisation = item.organisation
		docent = item.docent
		location = item.location
		participation = item.participation
		registration = item.registration
		extra = item.extra
	}
	let class_schedule: EducationItem['class_schedule'] | null = null
	if (channel === 'education_detail') {
		class_schedule = item.class_schedule
	}

	const isPast: boolean =
		isValid(new Date(date)) && !isFuture(endOfDay(new Date(date)))

	const showLink: boolean = url && has_detail ? true : false
	const LinkTag = showLink ? Link : 'div'
	const linkProps = showLink ? {href: url} : {}

	return (
		<div
			className={styles.calendaritem.is({past: isPast})()}
			data-theme={getTheme(
				item.channel === 'education_detail' ||
					item.channel === 'refresher_course_detail'
					? item.target_audience[0] || ''
					: ''
			)}
		>
			<LinkTag {...linkProps} className={styles.calendaritem.dateblock()}>
				<Day date={date} />
				{date !== end_date && <Day date={end_date} until={true} />}
			</LinkTag>
			<div className={styles.calendaritem.content()}>
				{title && (
					<Title.H4
						mod="uppercase"
						className={styles.calendaritem.content.title()}
					>
						{showLink ? <Link href={url}>{title}</Link> : title}
					</Title.H4>
				)}
				{organisation && (
					<LabelValue
						label={nl.calendar_item.labelvalues.organisation}
						value={organisation}
						width={labelValueWidth}
					/>
				)}
				{docent && (
					<LabelValue
						label={nl.calendar_item.labelvalues.docent}
						value={docent}
						width={labelValueWidth}
					/>
				)}
				{location && (
					<LabelValue
						label={nl.calendar_item.labelvalues.location}
						value={location}
						width={labelValueWidth}
					/>
				)}
				{startTime && (
					<LabelValue
						label={nl.calendar_item.labelvalues.starttime}
						value={startTime}
						width={labelValueWidth}
					/>
				)}
				{participation && (
					<LabelValue
						label={nl.calendar_item.labelvalues.participation}
						value={participation}
						width={labelValueWidth}
					/>
				)}
				{target_audience && (
					<LabelValue
						label={nl.calendar_item.labelvalues.target_audience}
						value={target_audience}
						width={labelValueWidth}
					/>
				)}
				{registration && (
					<LabelValue
						label={nl.calendar_item.labelvalues.registration}
						value={
							<Link
								href={registration.url}
								className={styles.calendaritem.content.link()}
							>
								{registration.description ||
									registration.title ||
									registration.url}
							</Link>
						}
						width={labelValueWidth}
					/>
				)}
				{class_schedule && (
					<LabelValue
						label={nl.calendar_item.labelvalues.class_schedule}
						value={
							<Link
								href={class_schedule.url}
								className={styles.calendaritem.content.link()}
							>
								{class_schedule.description ||
									class_schedule.title ||
									class_schedule.url}
							</Link>
						}
						width={labelValueWidth}
					/>
				)}
				{extra && (
					<LabelValue
						label={nl.calendar_item.labelvalues.extra}
						value={<Wysiwyg html={extra} />}
						width={labelValueWidth}
					/>
				)}
				{description && <LabelValue value={<Wysiwyg html={description} />} />}
			</div>
			<Moreinfo data={moreInfo} className={styles.calendaritem.moreinfo()} />
		</div>
	)
}
