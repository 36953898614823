import {format, getHours, isSameDay, isValid, startOfMonth} from 'date-fns'

import {nlBE} from 'date-fns/locale'

export const isValidCheck = (date): boolean => {
	if (date && isValid(new Date(date))) return true
	return false
}

const getFormat = (date: Date, formatString: string) => {
	return format(new Date(date), formatString, {locale: nlBE})
}

export function getDayMonth(date) {
	if (!isValidCheck(date)) return date
	return getFormat(date, 'dd/MM')
}

export function getDayMonthYear(date) {
	if (!isValidCheck(date)) return date
	return getFormat(date, 'dd/MM/yyyy')
}

export function getYearMonthDay(date) {
	if (!isValidCheck(date)) return date
	return getFormat(date, 'yyyy-MM-dd')
}

export function getDayOfWeek(date) {
	if (!isValidCheck(date)) return date
	return getFormat(date, 'eeee')
}

export function getMonth(date) {
	if (!isValidCheck(date)) return date
	return getFormat(date, 'MMMM')
}

export function getMonthYear(date) {
	if (!isValidCheck(date)) return date
	return getFormat(date, 'MMM yyyy')
}

export function getMonthYear2(date) {
	if (!isValidCheck(date)) return null
	return getFormat(date, 'MMM yy')
}

export function getDay(date) {
	if (!isValidCheck(date)) return null
	return getFormat(date, 'dd')
}

export function getFirstOfMonth(date): Date | null {
	if (!isValidCheck(date)) return null
	return startOfMonth(new Date(date))
}

function getHoursMinutes(date) {
	if (!isValidCheck(date)) return null
	return getFormat(date, 'HH:mm')
}

export function getFromToHours(date: string, end_date: string): string | null {
	const formattedDate: Date = new Date(date)
	const formattedEndDate: Date = new Date(end_date)

	if (!isValidCheck(formattedDate) || getHours(formattedDate) === 0) return null

	if (
		isValidCheck(formattedEndDate) &&
		isSameDay(formattedDate, formattedEndDate) &&
		formattedDate < formattedEndDate &&
		getHoursMinutes(formattedEndDate) !== '00:00'
	)
		return (
			getHoursMinutes(formattedDate) + ' - ' + getHoursMinutes(formattedEndDate)
		)

	if (
		isValidCheck(formattedEndDate) &&
		!isSameDay(formattedDate, formattedEndDate) &&
		formattedDate < formattedEndDate &&
		getHoursMinutes(formattedEndDate) !== '00:00'
	)
		return `${getHoursMinutes(formattedDate)} (${getDayMonth(
			formattedDate
		)}) - ${getHoursMinutes(formattedEndDate)} (${getDayMonth(
			formattedEndDate
		)})`

	return getHoursMinutes(formattedDate)
}
