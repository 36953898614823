'use client'

import {Icon} from 'layout/Icon'
import {Link} from 'layout/Link'
import css from './Pagination.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useCallback} from 'react'
import {usePathname} from 'next/navigation'

const styles = fromModule(css)

export const Pagination: React.FC<{
	visiblePages: number[]
	currentPage: number
	next: boolean
	prev: boolean
	params: URLSearchParams
	onClickHandler?: (s: number) => void
}> = ({visiblePages, currentPage, next, prev, params, onClickHandler}) => {
	const pathname = usePathname()

	const createQueryString = useCallback(
		(name: string, value: string): string => {
			const newParams = new URLSearchParams(params.toString())
			newParams.set(name, value)
			return newParams.toString()
		},
		[params]
	)

	if (visiblePages.length < 2 && !prev) return null

	const prevPage = visiblePages[0] - 1
	const prevUrl: string = prev
		? pathname + '?' + createQueryString('page', `${prevPage}`)
		: ''

	const nextPage = visiblePages[visiblePages.length - 1] + 1
	const nextUrl: string = next
		? pathname + '?' + createQueryString('page', `${nextPage}`)
		: ''

	return (
		<div className={styles.pagination()}>
			{prev && (
				<Link
					href={prevUrl}
					shallow={true}
					scroll={false}
					className={styles.pagination.prev()}
					onClick={() => onClickHandler && onClickHandler(prevPage)}
				>
					<Icon icon="arrow_left" />
				</Link>
			)}
			{visiblePages.map((page) => {
				const href: string =
					pathname + '?' + createQueryString('page', `${page}`)
				return (
					<Link
						href={href}
						shallow={true}
						scroll={false}
						className={styles.pagination.link.is({
							active: Number(page) === currentPage
						})()}
						key={page}
						onClick={() => onClickHandler && onClickHandler(page)}
					>
						{page}
					</Link>
				)
			})}
			{next && (
				<Link
					href={nextUrl}
					shallow={true}
					scroll={false}
					className={styles.pagination.next()}
					onClick={() => onClickHandler && onClickHandler(nextPage)}
				>
					<Icon icon="arrow_right" />
				</Link>
			)}
		</div>
	)
}
