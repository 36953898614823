'use client'

import {CMSMainChannel, CMSMainEducation_detailChannel} from 'cms-types'
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {ReadonlyURLSearchParams, useSearchParams} from 'next/navigation'

import {CalendarGroup} from './CalendarGroup'
import {CalendarGroups} from './CalendarGroups'
import {CalendarItem} from './CalendarItem'
import {Checkbox} from 'layout/Checkbox'
import css from './CalendarWithFilters.module.scss'
import {extendedTargetAudienceOptions} from 'options/extendedTargetAudienceOptions'
import {fromModule} from 'util/styler/Styler'
import {getSubLabel} from 'util/getsublabel'
import {nl} from 'locale'
import {omit} from 'util/omit'
import {optionsToValueLabels} from 'util/options'
import {pick} from 'util/pick'
import {provinceOptions} from 'options/provinceOptions'
import {targetAudienceOptions} from 'options/targetAudienceOptions'
import {typeOptions} from 'options/typeOptions'
import {unique} from 'util/unique'

const styles = fromModule(css)

type Filters = {
	province?: Set<string>
	target_audience?: Set<string>
	type?: Set<string>
}

const convertSearchParamsToFilters = (
	searchParams: ReadonlyURLSearchParams | null
): Filters => {
	if (!searchParams) return {}
	const filters: Filters = {}
	searchParams.forEach((value, key) => {
		if (!filters[key]) filters[key] = new Set<string>()
		filters[key].add(value)
	})
	return filters
}

const CalendarWithFilters: React.FC<{
	channel: CMSMainChannel['channel']
	groups: CalendarGroups
	notFound: string
}> = ({channel, groups, notFound}) => {
	const searchParams = useSearchParams()
	const [activeFfilters, setActiveFilters] = useState<Filters>(
		convertSearchParamsToFilters(searchParams)
	)
	const [filteredGroups, setFilteredGroups] = useState<CalendarGroups>(groups)

	useEffect(() => {
		const filterItem = (
			item: CalendarItem & {[K in keyof Filters]: string[]},
			key: keyof Filters
		): 0 | 1 => {
			if (
				item.channel === 'education_detail' ||
				item.channel === 'refresher_course_detail'
			) {
				const category = activeFfilters[key]
				if (category) {
					let hasMatch: boolean = false
					category.forEach((value) => {
						if (item[key].includes(value)) hasMatch = true
					})
					if (hasMatch) return 1
				}
			}

			return 0
		}

		const allActiveFilters = Object.keys(activeFfilters).filter(
			(filterKey) => (activeFfilters[filterKey] as Set<string>).size > 0
		)

		if (!Object.keys(activeFfilters).length || !allActiveFilters.length) {
			setFilteredGroups(groups)
		} else {
			setFilteredGroups(
				[...groups]
					.map((group: CalendarGroup) => ({
						...group,
						items: group.items.filter((item) => {
							let matches: number = 0
							for (const filterKey of Object.keys(
								activeFfilters
							) as (keyof Filters)[])
								matches += filterItem(item, filterKey)
							return matches === allActiveFilters.length
						})
					}))
					.filter(({items}) => items.length > 0)
			)
		}
	}, [activeFfilters, groups])

	const createQueryString = useCallback(
		(name: string, value: string): string => {
			const params = new URLSearchParams('')
			params.set(name, value)
			return params.toString()
		},
		[]
	)

	useEffect(() => {
		let qString: string[] = []
		Object.keys(activeFfilters).forEach((filterKey) => {
			;(activeFfilters[filterKey] as Set<string>).forEach((f) => {
				qString.push(createQueryString(filterKey, f))
			})
		})
		history.replaceState(null, '', '?' + qString.join('&'))
	}, [activeFfilters, createQueryString])

	return (
		<div className={styles.calendarWithFilters()}>
			<CalendarFilters
				channel={channel}
				activeFfilters={activeFfilters}
				setActiveFilters={setActiveFilters}
			/>
			<CalendarGroups
				groups={filteredGroups}
				notFound={notFound}
				className={styles.calendarWithFilters.groups()}
			/>
		</div>
	)
}

export default CalendarWithFilters

type ChangeHandler = (value: string, checked: boolean, key: string) => void

const CalendarFilters: React.FC<{
	activeFfilters: Filters
	channel: CMSMainChannel['channel']
	setActiveFilters: Dispatch<SetStateAction<Filters>>
}> = ({activeFfilters, channel, setActiveFilters}) => {
	if (channel !== 'refresher_courses' && channel !== 'educations') return null

	const onChangeHandler: ChangeHandler = (
		value: string,
		checked: boolean,
		key: string
	) => {
		const newFilters: Filters = {...activeFfilters}
		if (!Object.keys(newFilters).includes(key)) {
			newFilters[key] = new Set()
		}

		const category: Set<string> = newFilters[key]

		if (checked) {
			category.add(value)
		} else {
			if (category.has(value)) category.delete(value)
			newFilters[key] = category
		}
		setActiveFilters(newFilters)
	}

	return (
		<div className={styles.calendarWithFilters.filters()}>
			{channel === 'educations' && (
				<EducationTargetAudienceFilter
					onChangeHandler={onChangeHandler}
					activeFfilters={activeFfilters}
				/>
			)}
			{channel === 'refresher_courses' && (
				<RefresherCoursesTargetAudienceFilter
					onChangeHandler={onChangeHandler}
					activeFfilters={activeFfilters}
				/>
			)}
			<ProvinceFilter
				onChangeHandler={onChangeHandler}
				activeFfilters={activeFfilters}
			/>
			<TypeFilter
				onChangeHandler={onChangeHandler}
				activeFfilters={activeFfilters}
			/>
		</div>
	)
}

const ProvinceFilter: React.FC<{
	onChangeHandler: ChangeHandler
	activeFfilters: Filters
}> = ({onChangeHandler, activeFfilters}) => (
	<fieldset className={styles.calendarWithFilters.filters.fieldset()}>
		<legend className={styles.calendarWithFilters.filters.fieldset.legend()}>
			{nl.educations.filters.province}
		</legend>
		{optionsToValueLabels(provinceOptions).map((item, i) => (
			<Checkbox
				{...item}
				name="province"
				key={i}
				onChange={(e) =>
					onChangeHandler(e.target.value, e.target.checked, 'province')
				}
				checked={activeFfilters['province']?.has(item.value) || false}
			/>
		))}
	</fieldset>
)

const TypeFilter: React.FC<{
	onChangeHandler: ChangeHandler
	activeFfilters: Filters
}> = ({onChangeHandler, activeFfilters}) => (
	<fieldset className={styles.calendarWithFilters.filters.fieldset()}>
		<legend className={styles.calendarWithFilters.filters.fieldset.legend()}>
			{nl.educations.filters.type}
		</legend>
		{optionsToValueLabels(typeOptions).map((item, i) => (
			<Checkbox
				{...item}
				name="type"
				key={i}
				onChange={(e) =>
					onChangeHandler(e.target.value, e.target.checked, 'type')
				}
				checked={activeFfilters['type']?.has(item.value) || false}
			/>
		))}
	</fieldset>
)

const RefresherCoursesTargetAudienceFilter: React.FC<{
	onChangeHandler: ChangeHandler
	activeFfilters: Filters
}> = ({onChangeHandler, activeFfilters}) => {
	return (
		<fieldset className={styles.calendarWithFilters.filters.fieldset()}>
			<legend className={styles.calendarWithFilters.filters.fieldset.legend()}>
				{nl.educations.filters.target_audience}
			</legend>
			{optionsToValueLabels(targetAudienceOptions).map((item, i) => (
				<Checkbox
					{...item}
					name="target_audience"
					key={i}
					onChange={(e) =>
						onChangeHandler(e.target.value, e.target.checked, 'target_audience')
					}
					checked={activeFfilters['target_audience']?.has(item.value) || false}
				/>
			))}
		</fieldset>
	)
}

const getSubOptions = (optionsKey: string) =>
	pick(
		extendedTargetAudienceOptions,
		Object.keys(extendedTargetAudienceOptions).filter(
			(key) => key.substring(0, optionsKey.length) === optionsKey
		) as CMSMainEducation_detailChannel['target_audience']
	)

const NestedFilters: React.FC<{
	onChangeHandler: ChangeHandler
	filterKey: string
	options: {
		[key: string]: string
	}
	activeFfilters: Filters
}> = ({onChangeHandler, filterKey, options, activeFfilters}) => {
	const [selectedFilters, setSelectedFilters] = useState<string[]>(
		(
			Array.from(activeFfilters['target_audience'] || new Set()) as string[]
		).filter((f) => Object.keys(options).includes(f))
	)

	useEffect(() => {
		Object.keys(options).forEach((value) => {
			onChangeHandler(value, selectedFilters.includes(value), 'target_audience')
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFilters])

	return (
		<>
			<Checkbox
				value={targetAudienceOptions[filterKey]}
				name="target_audience"
				onChange={(e) => {
					if (!e.target.checked) setSelectedFilters([])
					else setSelectedFilters(Object.keys(options))
				}}
				checked={selectedFilters.length > 0}
			/>
			<div className={styles.calendarWithFilters.filters.fieldset.subitems()}>
				{optionsToValueLabels(options).map((item, i) => (
					<Checkbox
						{...item}
						label={getSubLabel(item.label)}
						name="target_audience"
						key={i}
						onChange={(e) => {
							if (!e.target.checked)
								setSelectedFilters(
									unique(selectedFilters.filter((f) => f !== e.target.value))
								)
							else
								setSelectedFilters(unique([...selectedFilters, e.target.value]))
						}}
						checked={selectedFilters.includes(item.value)}
					/>
				))}
			</div>
		</>
	)
}

const EducationTargetAudienceFilter: React.FC<{
	onChangeHandler: ChangeHandler
	activeFfilters: Filters
}> = ({onChangeHandler, activeFfilters}) => {
	const competitietrainersOptions = getSubOptions('competitietrainers')
	const recreatietrainersOptions = getSubOptions('recreatietrainers')
	const jeugdtrainersOptions = getSubOptions('jeugdtrainers')

	return (
		<fieldset className={styles.calendarWithFilters.filters.fieldset()}>
			<legend className={styles.calendarWithFilters.filters.fieldset.legend()}>
				{nl.educations.filters.target_audience}
			</legend>
			<NestedFilters
				onChangeHandler={onChangeHandler}
				filterKey={'competitietrainers'}
				options={competitietrainersOptions}
				activeFfilters={activeFfilters}
			/>
			<NestedFilters
				onChangeHandler={onChangeHandler}
				filterKey={'recreatietrainers'}
				options={recreatietrainersOptions}
				activeFfilters={activeFfilters}
			/>
			<NestedFilters
				onChangeHandler={onChangeHandler}
				filterKey={'jeugdtrainers'}
				options={jeugdtrainersOptions}
				activeFfilters={activeFfilters}
			/>
			{optionsToValueLabels(
				omit(extendedTargetAudienceOptions, [
					...Object.keys(competitietrainersOptions),
					...Object.keys(recreatietrainersOptions),
					...Object.keys(jeugdtrainersOptions)
				] as CMSMainEducation_detailChannel['target_audience'])
			).map((item, i) => (
				<Checkbox
					{...item}
					name="target_audience"
					key={i}
					onChange={(e) =>
						onChangeHandler(e.target.value, e.target.checked, 'target_audience')
					}
					checked={activeFfilters['target_audience']?.has(item.value) || false}
				/>
			))}
		</fieldset>
	)
}
