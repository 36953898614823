'use client'

import React, {FC} from 'react'

import {FetcherForm} from './FetcherForm'
import {Loading} from './Loading'
import {Paginated} from 'pages/api/fetch'
import {PaginatedFetcher} from 'layout/fetcher/PaginatedFetcher'
import {ResultPagination} from './ResultPagination'
import {Results} from './Results'
import {Title} from 'layout/Title'
import css from './FetcherResults.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type FetcherResultsMod = 'search' | 'documents'

export const FetcherResults: React.FC<{
	initial: Paginated<{}>
	title: string
	result: FC
	url: string
	mod: FetcherResultsMod
}> = ({initial, title, result, url = '/api/fetch/search', mod}) => {
	return (
		<PaginatedFetcher
			calculateUrl={(params) => {
				const isInitial = !params.get('page') && !params.get('search')
				if (isInitial) return null
				const apiParams = new URLSearchParams()
				apiParams.set('page', params.get('page') || '1')
				apiParams.set('search', params.get('search') || '')

				return `${url}?${apiParams.toString()}`
			}}
			initial={initial}
		>
			<div className={styles.fetcherresults()}>
				<Title.H1
					mod="uppercase"
					className={styles.fetcherresults.title.mod(mod)()}
				>
					{title}
				</Title.H1>
				<FetcherForm mod={mod} />
				<Results ResultComp={result} mod={mod} />
			</div>
			<Loading />
			<ResultPagination />
		</PaginatedFetcher>
	)
}
