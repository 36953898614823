'use client'

import css from './Loading.module.scss'
import {fromModule} from 'util/styler/Styler'
import {usePaginatedFetcher} from './PaginatedFetcher'

const styles = fromModule(css)

export const Loading: React.FC = () => {
	const {isLoading} = usePaginatedFetcher<{}>()
	if (!isLoading) return null

	return (
		<div className={styles.loading()}>
			<div className={styles.loading.spinner()}></div>
		</div>
	)
}
