'use client'

import {CMSMainClubChannel, CMSMainFacilityChannel} from 'cms-types'
import {useEffect, useState} from 'react'

import {FindClubFacility} from './FindClubFacility'
import css from './FindClubForm.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)
type LatLng = {
	latitude: number
	longitude: number
}

const DynamicFindClubMap = dynamic(() =>
	import('./FindClubMap').then((mod) => mod.FindClubMap)
)

export type Club = {
	title: CMSMainClubChannel['title']
	url: CMSMainClubChannel['url']
	facility: {
		id: CMSMainFacilityChannel['id']
		title: CMSMainFacilityChannel['title']
		address: CMSMainFacilityChannel['address']
		location: CMSMainFacilityChannel['location']
		city: CMSMainFacilityChannel['city']
		latitude: number
		longitude: number
	}
}

export const FindClubForm: React.FC<{clubs: Club[]}> = ({clubs}) => {
	const [search, setSearch] = useState<string>('')
	const [filteredClubs, setFilteredClubs] = useState<Club[]>(clubs)
	const [sortBy, setSortBy] = useState<'title' | 'distance'>('title')

	const sortByTitle = (a: Club, b: Club) =>
		a.title < b.title ? -1 : a.title > b.title ? 1 : 0

	const sortByDistance = (
		a: Club & {facility: Club['facility'] & {distance?: number}},
		b: Club & {facility: Club['facility'] & {distance?: number}}
	) => {
		if (!a.facility.distance && !b.facility.distance) return 0
		if (a.facility.distance && !b.facility.distance) return -1
		if (!a.facility.distance && b.facility.distance) return 1
		return (a.facility.distance as number) < (b.facility.distance as number)
			? -1
			: (a.facility.distance as number) > (b.facility.distance as number)
			? 1
			: 0
	}

	const getDistance = (
		pos1: LatLng,
		pos2: LatLng,
		unit: 'miles' | 'km' = 'km'
	): number => {
		var R = unit === 'miles' ? 3958.8 : 6371 // Radius of the Earth
		var rlat1 = pos1.latitude * (Math.PI / 180) // Convert degrees to radians
		var rlat2 = pos2.latitude * (Math.PI / 180) // Convert degrees to radians
		var difflat = rlat2 - rlat1 // Radian difference (latitudes)
		var difflon = (pos2.longitude - pos1.longitude) * (Math.PI / 180) // Radian difference (longitudes)

		var d =
			2 *
			R *
			Math.asin(
				Math.sqrt(
					Math.sin(difflat / 2) * Math.sin(difflat / 2) +
						Math.cos(rlat1) *
							Math.cos(rlat2) *
							Math.sin(difflon / 2) *
							Math.sin(difflon / 2)
				)
			)
		return d
	}

	useEffect(() => {
		const zipCode = Number(search)
		if (zipCode !== 0 && !isNaN(zipCode)) {
			setSortBy('distance')
			if (zipCode < 1000 || zipCode > 9999) setFilteredClubs([])
			else {
				const geocoder = new google.maps.Geocoder()
				geocoder.geocode({address: `${zipCode} België`}, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK && results) {
						const currentLatLng: LatLng = {
							latitude: results[0].geometry.location.lat(),
							longitude: results[0].geometry.location.lng()
						}
						setFilteredClubs(
							clubs
								.map((club, index) => {
									const distance = getDistance(
										currentLatLng,
										{
											latitude: club.facility.latitude,
											longitude: club.facility.longitude
										},
										'km'
									)

									return {
										...club,
										facility: {...club.facility, distance}
									}
								})
								.sort(sortByDistance)
						)
					}
				})
			}
		} else {
			setSortBy('title')
			setFilteredClubs(
				clubs
					.filter(({title}) => title.toLowerCase().includes(search))
					.sort(sortByTitle)
			)
		}
	}, [search, clubs])

	return (
		<div className={styles.findclubform()}>
			<div className={styles.findclubform.map()}>
				<DynamicFindClubMap
					clubs={filteredClubs}
					sortedByDistance={sortBy === 'distance'}
				/>
			</div>
			<div className={styles.findclubform.content()}>
				<form
					className={styles.findclubform.content.form()}
					onSubmit={(e) => {
						e.preventDefault()
					}}
				>
					<input
						type="search"
						placeholder="Postcode of club afkorting"
						className={styles.findclubform.content.form.input()}
						onChange={(e) => {
							setSearch(e.target.value.toLowerCase())
						}}
					/>
					<button className={styles.findclubform.content.form.submit()}>
						Zoeken
					</button>
				</form>
				{filteredClubs?.length > 0 && (
					<div className={styles.findclubform.facilities()}>
						{filteredClubs.map((club) => (
							<FindClubFacility
								club={club}
								sortedByDistance={sortBy === 'distance'}
								key={club.facility.id}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}
