import {CMSEntryLink, CMSExternalLink, CMSImage} from 'cms-types'

import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {Partners as PartnersType} from 'server/partners'
import {ServerComponent} from 'util/ServerComponent'
import css from './Partners.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Partners: ServerComponent<{
	partners: PartnersType
}> = ({partners}) => {
	if (!partners || partners.length === 0) return null

	return (
		<div className={styles.partners()}>
			<Container>
				<div className={styles.partners.content()}>
					<div className={styles.partners.stamp()} />
					<div className={styles.partners.items()}>
						<div className={styles.partners.items.row()}>
							{partners.map((item, i) => (
								<Partner {...item} key={i} />
							))}
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

const Partner: ServerComponent<{
	img: CMSImage
	url: CMSEntryLink | CMSExternalLink
}> = ({img, url}) => {
	if (!img?.src) return null

	return (
		<Link href={url?.url} className={styles.partner()}>
			<Image {...img} sizes="120px" className={styles.partner.img()} />
		</Link>
	)
}
