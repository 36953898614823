import {TopbarCard, TopbarCardType} from '../cards/TopbarCard'

import {Container} from '../../layout/Container'
import css from './Topbar.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Topbar: React.FC<{items: TopbarCardType[] | null}> = ({items}) => {
	if (!items || items.length === 0) return null

	return (
		<div className={styles.topbar()}>
			<Container>
				<div className={styles.topbar.items()}>
					{items.map((item, i) => (
						<div
							className={styles.topbar.items.item.is({
								fullwidth: item.fullwidth === true
							})()}
							key={i}
						>
							<TopbarCard {...item} />
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}
