/**
 * Keep the given object keys, remove the others
 */
export const pick = <T, K extends keyof T>(
	obj: T,
	keep: Array<K>
): Pick<T, K> => {
	if (!obj) return obj
	return Object.fromEntries(
		Object.entries(obj).filter(([key]) => keep.includes(key as K))
	) as Pick<T, K>
}
