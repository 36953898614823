'use client'

import {CMSImage, CMSWysiwyg} from 'cms-types'
import {
	EmblaNext,
	EmblaPagination,
	EmblaPrev
} from 'components/carousel/EmblaCarousel'
import {useCallback, useEffect, useState} from 'react'

import ClassNames from 'embla-carousel-class-names'
import {GalleryImage} from './GalleryBlock'
import css from './GallerySlides.module.scss'
import {fromModule} from 'util/styler/Styler'
import useEmblaCarousel from 'embla-carousel-react'

const styles = fromModule(css)

export const GallerySlides: React.FC<{
	slides: {gallery_img: CMSImage; gallery_img_text: CMSWysiwyg}[]
}> = ({slides}) => {
	const [emblaRef, embla] = useEmblaCarousel(
		{
			active: slides?.length >= 2,
			align: 'start',
			containScroll: 'trimSnaps'
		},
		[ClassNames()]
	)
	const [prevEnabled, setPrevEnabled] = useState<boolean>(false)
	const [nextEnabled, setNextEnabled] = useState<boolean>(false)
	const [selectedIndex, setSelectedIndex] = useState<number>(0)
	const [scrollSnaps, setScrollSnaps] = useState<Number[]>([])

	const onSelect = useCallback(() => {
		if (!embla) return
		setSelectedIndex(embla.selectedScrollSnap())
		setPrevEnabled(embla.canScrollPrev())
		setNextEnabled(embla.canScrollNext())
	}, [embla, setSelectedIndex])

	useEffect(() => {
		if (!embla) return
		onSelect()
		setScrollSnaps(embla.scrollSnapList())
		embla.on('select', onSelect)
	}, [embla, setScrollSnaps, onSelect])

	if (!slides || slides.length === 0) return null

	return (
		<div className={styles.embla()}>
			<div ref={emblaRef} className={styles.embla.viewport()}>
				<div className={styles.embla.slides()}>
					{slides.map((slide, index) => {
						if (!slide?.gallery_img?.src) return null

						return (
							<div className={styles.embla.slides.slide()} key={index}>
								<GalleryImage {...slide} />
							</div>
						)
					})}
				</div>
			</div>
			<EmblaPrev embla={embla} disabled={!prevEnabled} mod="absolute" />
			<EmblaNext embla={embla} disabled={!nextEnabled} mod="absolute" />
			<EmblaPagination
				embla={embla}
				scrollSnaps={scrollSnaps}
				selectedIndex={selectedIndex}
				className={styles.embla.pagination()}
			/>
		</div>
	)
}
