'use client'

import {Container} from 'layout/Container'
import {LinkStyled} from 'layout/LinkStyled'
import {NewsCardType} from 'components/cards/NewsCard'
import {NewsOverview} from './NewsOverview'
import {Title} from 'layout/Title'
import css from './NewsContent.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'

const styles = fromModule(css)

export const NewsContent: React.FC<{
	news: NewsCardType[] | null
	category?: string
}> = ({news, category}) => {
	if (!news || news.length === 0) return null

	const isClub: boolean = category && category === 'club' ? true : false

	return (
		<div className={styles.newscontent()}>
			<Container>
				<div className={styles.newscontent.content()}>
					<Title.H2 className={styles.newscontent.content.title()}>
						{nl.news.title}
					</Title.H2>
					<NewsOverview news={news} />
				</div>
				<LinkStyled
					href={isClub ? nl.clubs.news : nl.news.url}
					className={styles.newscontent.link()}
				>
					{isClub ? nl.clubs.readmore : nl.news.readmore}
				</LinkStyled>
			</Container>
		</div>
	)
}
