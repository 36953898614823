import {Ads} from 'components/ads/Ads'
import {Breadcrumbs} from 'components/breadcrumbs/Breadcrumbs'
import {CMSMainChannelModified} from 'server/node'
import {CMSMainRankingsChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {Filter} from './RankingsFilters'
import {Partners} from 'components/partners/Partners'
import {ServerComponent} from 'util/ServerComponent'
import {Title} from 'layout/Title'
import {Topbar} from 'components/topbar/Topbar'
import {Wysiwyg} from 'layout/wysiwyg/Wysiwyg'
import css from './Rankings.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import prisma from '../../database/prisma'

const DynamicQueryClientProvider = dynamic(
	() => import('util/QueryClientProvider')
)
const DynamicRankingsClient = dynamic(() => import('./RankingsClient'))

const styles = fromModule(css)

export const filterColumns = [
	'year',
	'isIndoor',
	'gender',
	'category',
	'discipline'
] as const

const getFilterValues = async (): Promise<Array<Filter>> => {
	const values = await prisma.result.groupBy({
		by: [...filterColumns]
	})
	return values
}

export const Rankings: ServerComponent<{
	page: Extract<
		CMSMainChannelModified,
		{channel: CMSMainRankingsChannel['channel']}
	>
}> = async ({page}) => {
	const {
		ads,
		intro,
		parents,
		partners,
		results_per_page: resultsPerPage,
		title,
		topbar
	} = page
	const filterValues = await getFilterValues()

	return (
		<DynamicQueryClientProvider>
			<>
				<div className={styles.rankings()}>
					<Topbar items={topbar} />
					<Breadcrumbs parents={parents} title={title} />
					<Container>
						<div className={styles.rankings.content()}>
							<Title.H1 mod="uppercase">{title}</Title.H1>
							{intro && (
								<Wysiwyg
									html={intro}
									className={styles.rankings.content.intro()}
								/>
							)}
							<DynamicRankingsClient
								filterValues={filterValues}
								resultsPerPage={resultsPerPage || 30}
							/>
						</div>
					</Container>
				</div>
				<Ads ads={ads} />
				<Partners partners={partners} />
			</>
		</DynamicQueryClientProvider>
	)
}
