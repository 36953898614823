'use client'

import {CMSImage} from 'cms-types'
import {Image} from 'layout/Image'
import {ImageProps} from 'next/image'
import React from 'react'
import css from './IntroImage.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const IntroImage: React.FC<{
	image: CMSImage
	img_text?: string
	sizes?: ImageProps['sizes']
	className?: string
}> = ({image, img_text, sizes = '350px', className}) => {
	if (!image?.src) return null

	return (
		<div className={styles.introimage.with(className)()}>
			<div className={styles.introimage.wrapper()}>
				<Image {...image} sizes={sizes} />
				{img_text && (
					<p className={styles.introimage.wrapper.text()}>{img_text}</p>
				)}
			</div>
			{image?.meta?.photographer && (
				<p className={styles.introimage.copyright()}>
					<b>Foto: </b>
					{image?.meta?.photographer}
				</p>
			)}
		</div>
	)
}
