import {Ads as AdsType} from 'server/ads'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {ServerComponent} from 'util/ServerComponent'
import css from './Ads.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Ads: ServerComponent<{ads: AdsType}> = ({ads}) => {
	if (!ads || !ads.length) return null

	return (
		<div className={styles.ads()}>
			<Container>
				<div className={styles.ads.items.mod(`length${ads?.length}`)()}>
					{ads.map((ad, key) => {
						return (
							<div className={styles.ads.items.item()} key={key}>
								<Link
									href={ad?.url?.url}
									className={styles.ads.items.item.image()}
								>
									{ad?.img?.src && <Image {...ad.img} fill sizes="100vw" />}
								</Link>
							</div>
						)
					})}
				</div>
			</Container>
		</div>
	)
}
