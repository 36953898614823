'use client'

import {Link} from 'layout/Link'
import {fromModule} from 'util/styler/Styler'
import css from './FindClubFacility.module.scss'
import {Club} from './FindClubForm'

const styles = fromModule(css)

export const FindClubFacility: React.FC<{
	club: Club & {facility: Club['facility'] & {distance?: number}}
	sortedByDistance: boolean
}> = ({club, sortedByDistance}) => {
	if (!club.url || !club.title) return null

	return (
		<Link href={club.url} className={styles.findclubfacility()}>
			<p className={styles.findclubfacility.subtitle()}>
				{club.facility.title}
			</p>
			<br />
			<p className={styles.findclubfacility.title()}>{club.title}</p>
			{sortedByDistance && club.facility.distance && (
				<>
					<br />
					<p className={styles.findclubfacility.distance()}>
						{Math.round(club.facility.distance * 100) / 100} km
					</p>
				</>
			)}
		</Link>
	)
}
