'use client'

import {Filter, PossibleFilterValues, RankingsFilters} from './RankingsFilters'
import {useCallback, useEffect, useState} from 'react'
import {usePathname, useRouter, useSearchParams} from 'next/navigation'

import {RankingsResults} from './RankingsResults'
import {filterColumns} from './Rankings'

const RankingsClient: React.FC<{
	filterValues: Array<Filter>
	resultsPerPage: number
}> = ({filterValues, resultsPerPage}) => {
	const [selectedFilters, setSelectedFilters] = useState<Partial<Filter>>({})
	const [possibleFilterValues, setPossibleFilterValues] =
		useState<PossibleFilterValues>(getPossibleFilterValues(filterValues, {}))
	const [showResults, setShowResults] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [isQuery, setIsQuery] = useState(false)
	const searchParams = useSearchParams()
	const searchParamsObject =
		searchParams && Object.fromEntries(searchParams.entries())
	const router = useRouter()
	const pathname = usePathname()

	const updateUrlSearchParams = useCallback(
		(
			params:
				| string
				| URLSearchParams
				| string[][]
				| Record<string, string>
				| undefined
				| (Partial<Filter> & {page?: number})
		) => {
			const newParams = new URLSearchParams(params as any)
			router.replace(`${pathname}?${newParams}`)
		},
		[router, pathname]
	)

	const checkSelectedValues = useCallback(
		(values: PossibleFilterValues) => {
			if (Object.keys(selectedFilters).length === 0) return

			// Check if only possible values are selected otherwise return empty filters
			if (
				!Object.entries(selectedFilters).every(([key, value]) =>
					values[key].includes(value)
				)
			) {
				setShowResults(false)
				setSelectedFilters({})
				updateUrlSearchParams({})
				return
			}

			// Based on possible filter values if only one value remains automatically select this value
			const newFilters = Object.entries(values).reduce((prev, [key, value]) => {
				if (key === 'gender') return prev
				if (Object.keys(selectedFilters).includes(key)) return prev
				if (value.length !== 1) return prev
				return {
					...prev,
					[key]: value[0]
				}
			}, selectedFilters)
			setSelectedFilters(newFilters as Filter)
		},
		[selectedFilters, updateUrlSearchParams]
	)

	// Fetch query parameters
	useEffect(() => {
		if (searchParamsObject && Object.keys(searchParamsObject).length === 0)
			return
		let params =
			searchParamsObject &&
			Object.fromEntries(
				Object.entries(searchParamsObject).map(([key, value]) => {
					if (key === 'year') return [key, Number(value)]
					if (key === 'isIndoor') return [key, value === 'true' ? true : false]
					return [key, value]
				})
			)
		const {page: curPage, ...filters} = params
		!curPage || isNaN(Number(curPage))
			? setPageNumber(1)
			: setPageNumber(Number(curPage))
		// Keep only parameters which match with filter column keys and possible filter values
		if (filters) {
			const newFilters = Object.entries(filters).map(([key, value]) => {
				if (!filterColumns.includes(key as keyof Filter)) return
				if (!possibleFilterValues[key].includes(value)) return
				return {[key]: value}
			})
			const newFiltersObject = Object.assign({}, ...newFilters)
			setSelectedFilters(newFiltersObject)
			setIsQuery(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Get possible filter values based on selected filters
	useEffect(() => {
		const possibleValues = getPossibleFilterValues(
			filterValues,
			selectedFilters
		)
		setPossibleFilterValues(possibleValues)
		checkSelectedValues(possibleValues)
	}, [selectedFilters, filterValues, checkSelectedValues])

	// Show results when filters come from url and filters are complete
	useEffect(() => {
		if (
			isQuery &&
			(filterColumns
				.filter((col) => col !== 'category')
				.every((value) => Object.keys(selectedFilters).includes(value)) ||
				filterColumns
					.filter((col) => col !== 'gender')
					.every((value) => Object.keys(selectedFilters).includes(value)))
		) {
			setIsLoading(true)
			setShowResults(true)
		} else if (isQuery) {
			updateUrlSearchParams({})
		}
	}, [isQuery, selectedFilters, updateUrlSearchParams])

	const handleSubmit = () => {
		if (isLoading) return
		if (!showResults) setIsLoading(true)
		updateUrlSearchParams(selectedFilters)
		setPageNumber(1)
		setShowResults(true)
	}

	const handleReset = () => {
		setSelectedFilters({})
		updateUrlSearchParams({})
		setPageNumber(1)
		setShowResults(false)
		setIsLoading(false)
	}

	const updatePage = (page: number) => {
		setPageNumber(page)
		if (page === 1) updateUrlSearchParams({...selectedFilters})
		if (page !== 1) updateUrlSearchParams({...selectedFilters, page: page})
	}

	return (
		<>
			<RankingsFilters
				filters={selectedFilters}
				submitFilters={(filters) => {
					setIsQuery(false)
					setShowResults(false)
					setSelectedFilters(filters)
				}}
				filterValues={possibleFilterValues}
				submitForm={handleSubmit}
				resetForm={handleReset}
				updateUrlSearchParams={updateUrlSearchParams}
			/>
			{showResults && (
				<RankingsResults
					filters={selectedFilters}
					page={pageNumber}
					isLoading={isLoading}
					resultsPerPage={resultsPerPage}
					updatePage={updatePage}
					setIsLoading={setIsLoading}
				/>
			)}
		</>
	)
}

const getPossibleFilterValues = (
	values: Array<Filter>,
	filters: Partial<Filter>
): PossibleFilterValues => {
	const uniquePossibleValues: PossibleFilterValues = filterColumns.reduce(
		(prev, curr) => {
			let possibleValues = values
			Object.keys(filters).forEach((filter) => {
				possibleValues = possibleValues.filter((item: Filter) => {
					// Don't filter own selection e.g. year should not be filtered by year
					if (filter === curr) return item
					// Filters for gender and category shouldn't interact
					if (curr === 'category' && filter === 'gender') return item
					if (curr === 'gender' && filter === 'category') return item
					// When gender filter is selected other filters should be filtered based on 'Senior' category
					if (filter === 'gender')
						return (
							item[filter] === filters[filter] &&
							item.category &&
							item.category.startsWith('Senior')
						)
					return item[filter] === filters[filter]
				})
			})
			const possibleValuesArray = Array.from(
				new Set(possibleValues.map((a) => a[curr]))
			)
			return {...prev, [curr]: possibleValuesArray}
		},
		{year: [], isIndoor: [], gender: [], category: [], discipline: []}
	)
	return uniquePossibleValues
}

export default RankingsClient
