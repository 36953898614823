'use client'

import {getMonthYear2, getYearMonthDay} from 'util/dateformat'
import {isSameMonth, startOfMonth} from 'date-fns'
import {useEffect, useReducer, useState} from 'react'
import {usePathname, useRouter, useSearchParams} from 'next/navigation'

import type {Month} from './NewsMonths'
import {NewsItems} from 'server/news'
import {NewsOverview} from './NewsOverview'
import {Pagination} from 'layout/pagination/Pagination'
import {chunk} from 'util/chunk'
import css from './News.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'

const styles = fromModule(css)

const DynamicNewsMonths = dynamic(() =>
	import('./NewsMonths').then((mod) => mod.NewsMonths)
)

const getMonths = (news: NewsItems): Month[] =>
	Array.from(
		new Set(
			news
				.map(({date}) => getYearMonthDay(startOfMonth(new Date(date))))
				.filter((i) => Boolean(i))
		)
	).map((month) => {
		const s = getMonthYear2(month)
		const m = (s && s.split(' ')?.shift()?.replace('.', '')) || ''
		const y = (s && s.split(' ').pop()) || ''
		const value = `${m}20${y}`
		const label = `${m} ’${y}`
		return {value, label, date: month}
	})

const getMothValue = (date: string): string | null => {
	const s = getMonthYear2(getYearMonthDay(startOfMonth(new Date(date))))
	const m = (s && s.split(' ')?.shift()?.replace('.', '')) || ''
	const y = (s && s.split(' ').pop()) || ''
	return `${m}20${y}`
}

const News: React.FC<{news: NewsItems}> = ({news}) => {
	const searchParams = useSearchParams()
	const [tag, setTag] = useState<string | null>(null)
	const [currentPage, setCurrentPage] = useState<number | null>(null)
	const [totalPages, setTotalPages] = useState<number | null>(null)
	const [selectedMonth, setSelectedMonth] = useState<string | null>(null)
	const filterNewsReducer = (state: NewsItems, tag: string): NewsItems =>
		tag ? news.filter((news) => news.tags.includes(tag)) : news
	const [filteredNews, setFilteredNews] = useReducer<
		(state: NewsItems, action: any) => NewsItems
	>(filterNewsReducer, news)
	const maxItemsInView: number = 9
	const maxPagesInView: number = 5
	const maxMonthsInView: number = 3
	const path = usePathname()
	const router = useRouter()

	useEffect(() => {
		if (searchParams) {
			// set the tag
			if (searchParams.get('tag') && searchParams.get('tag') !== tag) {
				setTag(searchParams.get('tag'))
				setSelectedMonth(null)
			}

			// set the currentPage
			if (
				searchParams.get('page') &&
				searchParams.get('page') !== `${currentPage}`
			) {
				let newCurrentPage = Number(searchParams.get('page')) || 1
				if (newCurrentPage < 1) newCurrentPage = 1
				if (totalPages && newCurrentPage > totalPages)
					newCurrentPage = totalPages
				if (totalPages) {
					setCurrentPage(newCurrentPage)
					setSelectedMonth(null)
					router.push(`${path}?page=${newCurrentPage}`)
				}
			}

			// set the selected month
			if (
				searchParams.get('month') &&
				searchParams.get('month') !== selectedMonth
			) {
				const newMonth: string | null = searchParams.get('month')
				const allMonths = getMonths(filteredNews)
				const monthIndex = allMonths.findIndex(({value}) => value === newMonth)
				if (monthIndex >= 0) {
					setSelectedMonth(newMonth)

					// calculate page
					const matchingNewsItemIndex = filteredNews.findIndex(({date}) =>
						isSameMonth(new Date(date), new Date(allMonths[monthIndex].date))
					)
					if (matchingNewsItemIndex >= 0) {
						const newPage: number = Math.ceil(
							matchingNewsItemIndex / maxItemsInView
						)
						setCurrentPage(newPage)
					}
				} else {
					setSelectedMonth(allMonths[0].value)
					setCurrentPage(1)
				}
			}
		}
	}, [
		searchParams,
		tag,
		selectedMonth,
		currentPage,
		totalPages,
		filteredNews,
		path,
		router
	])

	useEffect(() => {
		setFilteredNews(tag)
	}, [tag])

	useEffect(() => {
		const newTotalPages = Math.ceil(filteredNews.length / maxItemsInView)
		setTotalPages(newTotalPages)
	}, [filteredNews])

	useEffect(() => {
		if (totalPages && currentPage) {
			if (currentPage > totalPages) setCurrentPage(totalPages)
		}
	}, [totalPages, currentPage])

	const allMonths = getMonths(filteredNews)

	const visiblePages: number[] = []
	let startPageGroup = 1
	let stopPageGroup = maxPagesInView
	if (totalPages) {
		startPageGroup =
			(Math.ceil((currentPage || 1) / maxPagesInView) - 1) * maxPagesInView + 1
		if (startPageGroup > totalPages) startPageGroup = totalPages
		stopPageGroup = startPageGroup - 1 + maxPagesInView
		if (stopPageGroup > totalPages) stopPageGroup = totalPages
		for (let i = startPageGroup; i <= stopPageGroup; i++) {
			visiblePages.push(i)
		}
	}

	const startNewsFrom = ((currentPage || 1) - 1) * maxItemsInView
	const stopNewsAt = startNewsFrom + maxItemsInView

	const newsItems: NewsItems = filteredNews.slice(startNewsFrom, stopNewsAt)
	let prevMonth: Month | null = null
	let nextMonth: Month | null = null

	const monthChunks: Month[][] = chunk(allMonths, maxMonthsInView)
	let currentMonthChunk: Month[] = []
	if (!selectedMonth) {
		const currentMonthsInNewsOverview = Array.from(
			new Set(newsItems.map(({date}) => getMothValue(date)))
		)
		setSelectedMonth(currentMonthsInNewsOverview[0])
	} else {
		const currentMonthChunkIndex =
			monthChunks.findIndex(
				(ch) =>
					ch
						.map(({value}) => value)
						.find((value) => value === selectedMonth) !== undefined
			) || 0
		currentMonthChunk = monthChunks[currentMonthChunkIndex]
		nextMonth =
			(currentMonthChunkIndex < monthChunks.length - 1 &&
				monthChunks[currentMonthChunkIndex + 1][0]) ||
			null
		prevMonth =
			(currentMonthChunkIndex > 0 &&
				monthChunks[currentMonthChunkIndex - 1][maxMonthsInView - 1]) ||
			null
	}

	const params = new URLSearchParams(searchParams?.toString() || '')
	params.delete('month')

	return (
		<div className={styles.news()}>
			{!tag && (
				<DynamicNewsMonths
					months={currentMonthChunk}
					next={nextMonth}
					prev={prevMonth}
					selectedMonth={selectedMonth}
				/>
			)}
			{tag && (
				<div className={styles.news.tag()}>
					<p>{nl.news.withtag}</p>
					<p className={styles.news.tag.label()}>{tag}</p>
				</div>
			)}
			<NewsOverview news={newsItems} />
			<Pagination
				visiblePages={visiblePages}
				currentPage={currentPage || 1}
				next={stopPageGroup < (totalPages || 1)}
				prev={startPageGroup > 1}
				params={params}
			/>
		</div>
	)
}

export default News
