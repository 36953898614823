'use client'

import {FC} from 'react'
import {FetcherResultsMod} from './FetcherResults'
import css from './Results.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'
import {usePaginatedFetcher} from 'layout/fetcher/PaginatedFetcher'
import {useParams} from 'layout/fetcher/params'

const styles = fromModule(css)

export const Results: React.FC<{ResultComp: FC; mod: FetcherResultsMod}> = ({
	ResultComp,
	mod
}) => {
	const {data, isLoading} = usePaginatedFetcher<{}>()
	const {params, setParams} = useParams()

	const searchTerm = params.get('search')

	if (searchTerm && !isLoading && (!data || !data.length)) {
		return (
			<div className={styles.results.empty()}>
				{nl.fetcher[mod].empty_result.replace('::searchTerm::', searchTerm)}
			</div>
		)
	}

	if (!searchTerm && !isLoading) {
		return <div className={styles.results.empty()}>{nl.fetcher[mod].empty}</div>
	}

	if (isLoading) return null

	return (
		<div className={styles.results.mod(mod)()}>
			{!isLoading && data?.length > 0 && (
				<div className={styles.results.items()}>
					{data.map((result, i) => (
						<div className={styles.results.items.item()} key={i}>
							<ResultComp {...result} />
						</div>
					))}
				</div>
			)}
		</div>
	)
}
