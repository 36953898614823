import {PrismaClient} from '@prisma/client'

declare global {
	// allow global `var` declarations
	// eslint-disable-next-line no-var
	var prisma: PrismaClient | undefined
}

// export const prisma =
// 	global.prisma ||
// 	new PrismaClient({
// 		// log: ['query']
// 	})

// if (process.env.NODE_ENV !== 'production') global.prisma = prisma

let prisma: PrismaClient

const getDbHost = () =>
	process.env.GITHUB_ACTIONS === 'true'
		? process.env.DATABASE_URL_EXTERNAL_ACCESS
		: process.env.DATABASE_URL

if (typeof window === 'undefined') {
	if (process.env.NODE_ENV === 'production') {
		prisma = new PrismaClient({
			datasources: {
				db: {
					url: getDbHost()
				}
			}
		})
	} else {
		if (!global.prisma) {
			global.prisma = new PrismaClient({
				datasources: {
					db: {
						url: getDbHost()
					}
				}
			})
		}

		prisma = global.prisma
	}
}
//@ts-ignore
export default prisma
