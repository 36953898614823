import {CMSImage, CMSMainGalleryBlock, CMSWysiwyg} from 'cms-types'

import {Block} from 'blocks/Block'
import {GallerySlides} from './GallerySlides'
import {Image} from 'layout/Image'
import css from './GalleryBlock.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const GalleryBlock: React.FC<{block: CMSMainGalleryBlock}> = ({
	block
}) => {
	const {type, gallery} = block
	if (!gallery || gallery.length === 0) return null

	return (
		<Block type={type}>
			{gallery.length === 1 && <GalleryImage {...gallery[0]} />}
			{gallery.length >= 2 && <GallerySlides slides={gallery} />}
		</Block>
	)
}

export const GalleryImage: React.FC<{
	gallery_img: CMSImage
	gallery_img_text: CMSWysiwyg
}> = ({gallery_img, gallery_img_text}) => {
	if (!gallery_img?.src) return null

	return (
		<div className={styles.image()}>
			<div className={styles.image.img()}>
				<Image {...gallery_img} fill sizes="560px" />
			</div>
			{gallery_img_text && (
				<p className={styles.image.text()}>{gallery_img_text}</p>
			)}
		</div>
	)
}
