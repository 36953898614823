import {CMSImage, CMSLink} from 'cms-types'

import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {Title} from 'layout/Title'
import css from './TopbarCard.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type TopbarCardType = {
	img: CMSImage
	link: CMSLink
	title: string
	center?: boolean
	color?: string
	fullwidth?: boolean
}

export const TopbarCard: React.FC<TopbarCardType> = ({
	img,
	link,
	title,
	center,
	color,
	fullwidth
}) => {
	if (!img?.src && !title) return null
	const bgColor = center && color && `${color}`

	return (
		<Link href={link?.url} className={styles.topbarcard()}>
			<div
				className={styles.topbarcard.image.is({
					fullwidth: fullwidth === true
				})()}
				style={bgColor ? {backgroundColor: bgColor} : undefined}
			>
				{img?.src && (
					<Image
						{...img}
						fill
						priority
						sizes={
							fullwidth
								? '(max-width: 1080px) 100vw, 320px'
								: '(max-width: 600px) 100vw, 320px'
						}
						style={center ? {objectFit: 'contain'} : undefined}
						className={styles.topbarcard.image.bg()}
						withLoader={true}
					/>
				)}
			</div>
			{title && (
				<div className={styles.topbarcard.content()}>
					<Title.H5 as="h4" className={styles.topbarcard.content.title()}>
						{title}
					</Title.H5>
				</div>
			)}
		</Link>
	)
}
