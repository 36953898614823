/**
 * Remove the given object keys, keep the others
 */
export const omit = <T, K extends keyof T>(
	obj: T,
	remove: Array<K> | ReadonlyArray<K>
): Omit<T, K> => {
	if (!obj) return obj
	return Object.fromEntries(
		Object.entries(obj).filter(([key]) => !remove.includes(key as K))
	) as Omit<T, K>
}
