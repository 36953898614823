import {Icon, IconKey} from './Icon'
import React, {HTMLProps} from 'react'

import {Link} from 'layout/Link'
import css from './Button.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

type ButtonType = (
	| Omit<HTMLProps<HTMLAnchorElement>, 'as'>
	| ({as: 'a'} & HTMLProps<HTMLAnchorElement>)
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>)
) & {
	to?: string
	iconbefore?: IconKey
	iconafter?: IconKey
}

export const Button: React.FC<ButtonType> = ({
	children,
	iconbefore,
	iconafter,
	...props
}) => {
	let ButtonTag: any = Link
	if ('as' in props && props.as === 'button') ButtonTag = 'button'

	return (
		<ButtonTag {...props} className={styles.button.mergeProps(props)()}>
			{iconbefore && <ButtonIcon icon={iconbefore} />}
			<ButtonText>{children}</ButtonText>
			{iconafter && <ButtonIcon icon={iconafter} />}
		</ButtonTag>
	)
}

const ButtonText: React.FC<{children: React.ReactNode}> = (props) => (
	<span {...props} className={styles.text()} />
)

const ButtonIcon: React.FC<{icon: IconKey}> = ({icon}) => (
	<span className={styles.icon.mod(icon)()}>
		<Icon icon={icon} />
	</span>
)
