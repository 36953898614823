'use client'

import {FetcherResultsMod} from './FetcherResults'
import {Icon} from 'layout/Icon'
import css from './FetcherForm.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'
import {useParams} from 'layout/fetcher/params'
import {useState} from 'react'

const styles = fromModule(css)

export const FetcherForm: React.FC<{mod: FetcherResultsMod}> = ({mod}) => {
	const {params, setParams} = useParams()
	const [searchTerm, setSearchTerm] = useState<string>(
		params.get('search') || ''
	)
	return (
		<form
			className={styles.fetcherform()}
			onSubmit={(e) => {
				e.preventDefault()
				if (searchTerm.length > 0 && searchTerm.length < 3) return
				const newParams = new URLSearchParams()
				// newParams.set('search', searchTerm)
				newParams.delete('search')
				newParams.append('search', searchTerm)
				newParams.delete('page')
				newParams.append('page', '1')
				setParams(newParams)
			}}
		>
			<label className={styles.fetcherform.search()}>
				<span className={styles.fetcherform.search.icon()}>
					<Icon icon="search" />
				</span>
				<input
					type="search"
					placeholder={nl.fetcher[mod].placeholder}
					className={styles.fetcherform.search.input()}
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</label>
			<button className={styles.fetcherform.submit()}>Zoek</button>
		</form>
	)
}
