'use client'

import {
	Contentside,
	ContentsideContent,
	ContentsideSidebar
} from 'components/contentside/Contentside'

import {CMSImage} from 'cms-types'
import {IntroImage} from 'components/introimage/IntroImage'
import React from 'react'
import {Title} from 'layout/Title'
import css from './IntroContent.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'

const styles = fromModule(css)

export const IntroContent: React.FC<{
	title: string
	img: CMSImage
	img_display?: 'default' | 'full'
	className?: string
	children?: React.ReactNode
	sidebarChildren?: React.ReactNode
	hideSidebarWhenEmpty?: boolean
}> = ({
	title,
	img,
	img_display,
	className,
	sidebarChildren,
	children,
	hideSidebarWhenEmpty = false
}) => {
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	let sidebarIsEmpty = true
	if (sidebarChildren) sidebarIsEmpty = false
	if (isIpadLand && img_display !== 'full' && img && img?.src)
		sidebarIsEmpty = false
	const sidebarIsVisible =
		!sidebarIsEmpty || (sidebarIsEmpty && !hideSidebarWhenEmpty)

	return (
		<Contentside className={styles.introcontent.with(className)()}>
			<ContentsideContent>
				<Title.H1 mod="uppercase">{title}</Title.H1>
				{(!isIpadLand || img_display === 'full') && (
					<IntroImage
						image={img}
						sizes="(max-width: 1024px) 100vw,
                    700px"
						className={styles.introcontent.image()}
					/>
				)}
				{children}
			</ContentsideContent>
			{sidebarIsVisible && (
				<ContentsideSidebar>
					{isIpadLand && img_display !== 'full' && img && img?.src && (
						<IntroImage image={img} />
					)}
					{sidebarChildren}
				</ContentsideSidebar>
			)}
		</Contentside>
	)
}
