'use client'

import {FetcherResults} from 'layout/fetcher/FetcherResults'
import {LinkStyled} from 'layout/LinkStyled'
import {Paginated} from 'pages/api/fetch'
import {SearchType} from './Search'
import {Wysiwyg} from 'layout/wysiwyg/Wysiwyg'
import css from './SearchResults.module.scss'
import {fromModule} from 'util/styler/Styler'
import {getDayMonthYear} from 'util/dateformat'
import {nl} from 'locale'

const styles = fromModule(css)

export const SearchResults: React.FC<{
	initial: Paginated<SearchType>
	title: string
}> = ({initial, title}) => {
	return (
		<FetcherResults
			initial={initial}
			title={title}
			url={'/api/fetch/search'}
			mod={'search'}
			result={Result}
		/>
	)
}

const Result: React.FC<SearchType> = (result) => {
	return (
		<div className={styles.result()}>
			<div className={styles.result.title()}>
				<LinkStyled href={result.url} className={styles.result.title.text()}>
					<Wysiwyg html={result.title} />
				</LinkStyled>
				<div className={styles.result.type()} data-theme={result.category}>
					{nl.search.channels[result.channel]}
				</div>
				<div className={styles.result.date()}>
					{result.date
						? getDayMonthYear(result.date)
						: getDayMonthYear(result.modified)}
				</div>
			</div>
			<Wysiwyg html={result.description} className={styles.result.text()} />
		</div>
	)
}
