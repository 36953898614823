import React, {useCallback} from 'react'

import type {EmblaCarouselType} from 'embla-carousel'
import {Icon} from 'layout/Icon'
import {Variant} from 'util/styler'
import {fromModule} from 'util/styler/Styler'
import css from './EmblaCarousel.module.scss'

const styles = fromModule(css)

export const EmblaPrev: React.FC<{
	embla: EmblaCarouselType | undefined
	disabled?: boolean
	mod?: Variant<'absolute'>
}> = ({embla, disabled, mod}) => {
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])

	return (
		<button
			onClick={scrollPrev}
			disabled={disabled}
			className={styles.prev.mod(mod)()}
		>
			<Icon icon="arrow_left" className={styles.prev.icon()} />
		</button>
	)
}

export const EmblaNext: React.FC<{
	embla: EmblaCarouselType | undefined
	disabled?: boolean
	className?: string
	mod?: Variant<'absolute'>
}> = ({embla, disabled, mod}) => {
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

	return (
		<button
			onClick={scrollNext}
			disabled={disabled}
			className={styles.next.mod(mod)()}
		>
			<Icon icon="arrow_right" className={styles.next.icon()} />
		</button>
	)
}

export const EmblaPagination: React.FC<{
	embla: EmblaCarouselType | undefined
	scrollSnaps: any[]
	selectedIndex: number
	className?: string
}> = ({embla, scrollSnaps, selectedIndex, className}) => {
	const scrollTo = useCallback(
		(index) => embla && embla.scrollTo(index),
		[embla]
	)

	if (!scrollSnaps) return null

	return (
		<div className={styles.pagination.with(className)()}>
			{scrollSnaps.map((_, index) => (
				<button
					key={index}
					onClick={() => scrollTo(index)}
					className={styles.pagination.item.is({
						active: index === selectedIndex
					})()}
				/>
			))}
		</div>
	)
}
