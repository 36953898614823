export const formatPhone = (phone: string): string => {
	let formattedPhone: string = phone
	let rest: string = ''
	if (phone.substring(0, 4) === '0032') {
		rest = phone.substring(4)
	} else if (phone.substring(0, 1) === '0' && phone.substring(0, 2) !== '00') {
		rest = phone.substring(1)
	}
	if (rest !== '') {
		if (rest.length === 9) {
			formattedPhone = `+32 (0)${rest.substring(0, 3)} ${rest.substring(
				3,
				5
			)} ${rest.substring(5, 7)} ${rest.substring(7)}`
		} else {
			if (['2', '3', '4', '9'].includes(phone.substring(4, 5))) {
				formattedPhone = `+32 (0)${rest.substring(0, 1)} ${rest.substring(
					1,
					4
				)} ${rest.substring(4, 6)} ${rest.substring(6)}`
			} else {
				formattedPhone = `+32 (0)${rest.substring(0, 2)} ${rest.substring(
					2,
					4
				)} ${rest.substring(4, 6)} ${rest.substring(6)}`
			}
		}
	}
	return formattedPhone
}
