'use client'

import {useEffect, useState} from 'react'

import {AppEnv} from 'types'
import {CMSMainChannel} from 'cms-types'
import css from './AdminTools.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useSearchParams} from 'next/navigation'

const styles = fromModule(css)

type AdminTools = Pick<
	CMSMainChannel,
	'id' | 'language' | 'project_key' | 'url'
>

export const useIsAdmin = (app_env: AppEnv) => {
	const [isAdmin, setIsAdmin] = useState<boolean>(false)

	useEffect(() => {
		if (typeof window === 'undefined') return
		if (!localStorage.admin_enabled) return
		if (!localStorage.admin_refreshed) return

		const validUntil =
			parseInt(localStorage.admin_refreshed, 10) + 1000 * 60 * 60 * 24 // valid for 24 hours
		if (Date.now() > validUntil) return

		setIsAdmin(true)
	}, [app_env])

	return isAdmin
}

const AdminTools: React.FC<AdminTools> = ({id, url, language, project_key}) => {
	const app_env = process.env.NEXT_PUBLIC_APP_ENV as AppEnv
	const isAdmin = useIsAdmin(app_env)
	const router = useSearchParams()

	if (!isAdmin) return null
	if (router && router.get('preview')) return null
	if (!id || !language || !project_key || !url) return null

	const editUrl =
		id && language && project_key
			? `${process.env.NEXT_PUBLIC_ADMIN_URL}/project/${project_key}/${language}/entry/${id}`
			: process.env.NEXT_PUBLIC_ADMIN_URL

	return (
		<div className={styles.tools()}>
			<a
				href={editUrl}
				target="_blank"
				rel="noreferrer"
				className={styles.tools.button()}
			>
				Edit
			</a>
			{app_env === 'development' && (
				<a
					href={`/api/data${url}`}
					target="_blank"
					rel="noreferrer"
					className={styles.tools.button()}
				>
					JSON
				</a>
			)}
		</div>
	)
}

export default AdminTools
