'use client'

import {DocumentType} from './Documents'
import {FetcherResults} from 'layout/fetcher/FetcherResults'
import {LinkStyled} from 'layout/LinkStyled'
import {Paginated} from 'pages/api/fetch'
import React from 'react'
import {Wysiwyg} from 'layout/wysiwyg/Wysiwyg'
import css from './DocumentResults.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const DocumentResults: React.FC<{
	initial: Paginated<DocumentType>
	title: string
}> = ({initial, title}) => {
	return (
		<FetcherResults
			initial={initial}
			title={title}
			url={'/api/fetch/document'}
			mod={'documents'}
			result={Result}
		/>
	)
}

const Result: React.FC<DocumentType> = (result) => {
	return (
		<div className={styles.result()}>
			<LinkStyled className={styles.result.item()} href={result.local_path}>
				<Wysiwyg html={result.name} />
			</LinkStyled>
		</div>
	)
}
