'use client'

import React, {PropsWithChildren} from 'react'

import {Button} from 'layout/Button'
import css from './CookiesMissing.module.scss'
import {fromModule} from 'util/styler/Styler'
import {nl} from 'locale'
import {useCookiebot} from 'util/cookiebot'

const styles = fromModule(css)

export const CookiesMissing: React.FC<
	PropsWithChildren<{
		category: 'necessary' | 'preferences' | 'statistics' | 'marketing'
	}>
> = ({category, children}) => {
	const cookiebot = useCookiebot()
	if (cookiebot.hasConsented(category)) return <>{children}</>

	return (
		<div className={styles.cookiesmissing()}>
			<p className={styles.cookiesmissing.text()}>
				{nl.cookiesmissing.text(category)}
			</p>
			<Button
				onClick={() => cookiebot.renew()}
				className={styles.cookiesmissing.button()}
			>
				{nl.cookiesmissing.button}
			</Button>
		</div>
	)
}
